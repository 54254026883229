.Button {
  font-family: 'AvenirNextLTProBold';
  font-weight: 500;
  border: 2px solid transparent;
  min-height: 3.75rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  padding: 1.125rem 1.5rem;
  border-radius: 3.75rem;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.03125em;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.ButtonCircle {
  height: 3.75rem;
  width: 3.75rem;
  line-height: 3.75rem;
  padding: 0;
}

.ButtonMedium {
  height: 2.875rem;
  width: 2.875rem;
  line-height: 2.875rem;
  border-radius: 2.875rem;
  &.ButtonCircle {
    min-height: 2.875rem;
    height: 2.875rem;
  }
}

.ButtonDefault {
  border-color: #71757d;
  color: #71757d;
  background: transparent;
  &:hover{
    border-color: #71757d;
    color: #FFF;
    background: rgba(#71757d, 0.5);
  }

  &:focus, &:active {
    color: #FFF;
    background: #71757d;
  }
}

.ButtonPrimary {
  border-color: $color-primary;
  color: #FFF;
  background: transparent;

  &:hover{
    border-color: $color-primary;
    color: #FFF;
    background: rgba($color-primary, 0.5);
  }

  &:focus, &:active{
    background: $color-primary;
  }
}

.ButtonSecondary{
  border-color: $color-secondary;
  color: #FFF;
  background: transparent;

  &:hover{
    border-color: $color-secondary;
    color: #FFF;
    background: rgba($color-secondary, 0.5);
  }

  &:focus, &:active{
    background: $color-secondary;
  }
}

.ButtonGold{
  border-color: $gold;
  color: #FFF;
  background: transparent;

  &:hover{
    border-color: $gold;
    color: #FFF;
    background: rgba($gold, 0.5);
  }

  &:focus, &:active{
    background: $gold;
  }
}

.ButtonOneBorder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.ButtonOneBorder-button {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ButtonOneBorder-button:hover {
  cursor: pointer;
}

.ButtonOneBorder-button a {
  display: block;
}

.ButtonOneBorder-label {
  font-family: 'AvenirNextLTProBold';
  white-space: nowrap;
  padding: 0 0 1rem;
  font-size: 1.8rem;
  color: #ffffff;
}

.ButtonOneBorder.ButtonOneBorder-primary .ButtonOneBorder-labelBorder {
  background: #00ae75;
}

.ButtonOneBorder.ButtonOneBorder-secondary .ButtonOneBorder-labelBorder {
  background: #4083c9;
}

.ButtonOneBorder.ButtonOneBorder-orange .ButtonOneBorder-labelBorder {
  background: #ff9900;
}

.ButtonOneBorder-labelBorder {
  width: 100%;
  height: 0.3rem;
  background: #00ae75;
}

.ButtonOneBorder.ButtonOneBorder-left {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.ButtonOneBorder.ButtonOneBorder-left .ButtonOneBorder-labelBorder {
  width: 0;
  height: 3rem;
  background: #00ae75;
  -webkit-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

.ButtonOneBorder.ButtonOneBorder-left .ButtonOneBorder-label {
  padding: 0;
}

.ButtonOneBorder.ButtonOneBorder-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (min-width: 1000px) {
  .ButtonOneBorder-labelBorder {
    width: 0;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .ButtonOneBorder-label {
    font-size: 2rem;
  }

  .ButtonOneBorder--active .ButtonOneBorder-label,
  .ButtonOneBorder:hover .ButtonOneBorder-label {
    color: #ffffff;
  }

  .ButtonOneBorder--active.ButtonOneBorder-primary .ButtonOneBorder-label,
  .ButtonOneBorder:hover.ButtonOneBorder-primary .ButtonOneBorder-label {
    color: #00ae75;
  }

  .ButtonOneBorder--active.ButtonOneBorder-secondary .ButtonOneBorder-label,
  .ButtonOneBorder:hover.ButtonOneBorder-secondary .ButtonOneBorder-label {
    color: #4083c9;
  }

  .ButtonOneBorder--active.ButtonOneBorder-orange .ButtonOneBorder-label,
  .ButtonOneBorder:hover.ButtonOneBorder-orange .ButtonOneBorder-label {
    color: #ff9900;
  }

  .ButtonOneBorder--active .ButtonOneBorder-labelBorder,
  .ButtonOneBorder:hover .ButtonOneBorder-labelBorder {
    -webkit-transition: width 0.5s ease-in;
    -o-transition: width 0.5s ease-in;
    transition: width 0.5s ease-in;
    width: 100%;
  }

  .ButtonOneBorder--active.ButtonOneBorder-left,
  .ButtonOneBorder:hover.ButtonOneBorder-left {
    background: #090f14;
  }

  .ButtonOneBorder--active.ButtonOneBorder-left .ButtonOneBorder-labelBorder,
  .ButtonOneBorder:hover.ButtonOneBorder-left .ButtonOneBorder-labelBorder {
    -webkit-transition: width 0.2s linear;
    -o-transition: width 0.2s linear;
    transition: width 0.2s linear;
    width: 0.5rem;
    height: 3rem;
  }
}
