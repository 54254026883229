.modal.modal-bw {
  background-color: #040609;
  color: #FFF;
  display: block;
  height: 100vh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1050;
  padding: 0;
  transform: translate(0, 100%);
  transition: 0.6s transform  cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 1;
  overflow: hidden;
  &.in{
    transform: translate(0, 0);
  }
}

.modal-bw{
  .modal-title{
    text-align: center;
    font-size: 5.2rem;
    font-family: $font-family-avenir-next-lt-pro-bold;
    padding: 0 0 2.5rem;
    margin: 0;
    text-align: center;
    margin-top: 11rem;
  }
  .modal-content{
    background: transparent;
    padding: 0;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    max-height: calc(100vh - 20rem);
  }
  .nicescroll-rails{
    top: auto !important;
    bottom: 0;
  }
  @media all and (max-width: 1366px) {
    .modal-title{
      font-size: 3.3rem;
      margin-top: 4rem;
    }
    .modal-content{
      max-height: calc(100vh - 11rem);
    }
  }
}



.modal-safety{
  .col{
    opacity: 0;
  }
  &.modal-bw.bounceInUp .col{
    opacity: 1;
    transition: 1s all  0.6s;
    &:nth-child(2){
      transition-delay: 0.8s;
    }
    &:nth-child(3){
      transition-delay: 1.0s;
    }
    &:nth-child(4){
      transition-delay: 1.2s;
    }
    &:nth-child(5){
      transition-delay: 1.4s;
    }
    &:nth-child(6){
      transition-delay: 1.6s;
    }
  }
}
.row-safety-tips{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 77%;
  margin: 0 auto;
  padding-bottom: 2rem;
  > .col{
    padding: 0;
    flex: 0 0 33.3%;
  }
  @media all and (max-width: 1366px) {
    width: 86%;
  }

  @media all and (max-width: 767px) {
    > .col{
      padding: 0;
      flex: 0 0 50%;
    }
  }

  @media all and (max-width: 480px) {
    > .col{
      padding: 0;
      flex: 0 0 100%;
    }
  }
}
.safety-tips-item{
  text-align: center;
  padding: 2.5rem 5rem 2.5rem 5rem;
  .image-box{
    max-width: 45%;
    margin: 0 auto;
    position: relative;
    &:before {
      position: absolute;
      background: url('../images/bitcoin/shadow_blue.png') no-repeat center center;
      background-size: contain;
      width: 180%;
      height: 130%;
      content: "";
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0 auto;
    }
    img{
      display: block;
      width: 100%;
      position: relative;
      z-index: 2;
      margin: 0 auto;
    }
  }
  .text-box{
    margin-top: 3rem;
    p{
      font-size: 1.6rem;
      line-height: 1.375;
      margin: 0;
      font-family: $font-family-montserrat-light;
    }
  }
  @media all and (max-width: 1366px) {
    padding-left: 2rem;
    padding-right: 2rem;
    .image-box{
      max-width: 35%;
    }
    .text-box{
      p{
        font-size: 1.4rem;
      }
    }
  }
}

.modal-deposit{
  .col{
    opacity: 0;
  }
  &.modal-bw.bounceInUp .col{
    opacity: 1;
    transition: 1s all  3s;
    &:nth-child(1){
      transition-delay: 0.6s;
    }
    &:nth-child(2){
      transition-delay: 0.8s;
    }
    &:nth-child(3){
      transition-delay: 1.0s;
    }
    &:nth-child(4){
      transition-delay: 1.2s;
    }
    &:nth-child(5){
      transition-delay: 1.4s;
    }
    &:nth-child(6){
      transition-delay: 1.6s;
    }
    &:nth-child(7){
      transition-delay: 1.8s;
    }
    &:nth-child(8){
      transition-delay: 2.0s;
    }
    &:nth-child(9){
      transition-delay: 2.2s;
    }
    &:nth-child(10){
      transition-delay: 2.4s;
    }
    &:nth-child(11){
      transition-delay: 2.6s;
    }
    &:nth-child(12){
      transition-delay: 2.8s;
    }
  }
}

.bitcoin-exchange-subtitle {
  margin: 0 auto 2rem auto;
  max-width: 80rem;
  padding: 0 5rem;
  text-align: center;
  font-size: 2rem;
  line-height: 1.4;
  @media all and (max-width: 1366px) {
    font-size: 1.8rem;
  }
}

.row-bitcoin-exchange{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 77%;
  margin: 0 auto;
  padding-top: 6rem;
  padding-bottom: 2rem;
  > .col{
    padding: 0;
    flex: 0 0 25%;
  }
  @media all and (max-width: 1366px) {
    width: 96%;
    padding-top: 0;
  }
  @media all and (max-width: 1260px) {
    > .col{
      flex: 0 0 33.33%;
    }
  }
  @media all and (max-width: 767px) {
    > .col{
      flex: 0 0 50%;
    }
  }

  @media all and (max-width: 480px) {
    > .col{
      flex: 0 0 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.bitcoin-exchange-item{
  padding: 0 2rem 4rem 2rem;
  .image-box{
    display: block;
    height: 8rem;
    overflow: hidden;
    position: relative;
    a{
      display: block;
      color: #686a6b;
      font-size: 20rem;
      vertical-align: top;
      transition: 0.25s color;
      position: absolute;
      transform: translate(0, -25%);
    }
  }
  .text-box{
    padding: 1.5rem 0 0 0;
    p{
      text-align: left;
      color: #8e8e8f;
      font-size: 1.6rem;
      line-height: 1.375;
      margin: 0 0 4rem 0;
      padding: 0 0 0 3rem;
      font-family: $font-family-montserrat-light;
      position: relative;
      transition: 0.25s color;
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        background: $color-secondary;
        content: "";
        height: 100%;
      }
    }
    .btn{
      padding-left: 3.4rem;
      padding-right: 3.4rem;
    }
  }
  &:hover{
    .image-box{
      a{
        color: #FFF;
      }
    }
    .text-box{
      p{
        color: #FFF;
      }
      .btn {
        border-color: #4083c9;
        background-color: #4083c9;
      }
    }
  }
  @media all and (max-width: 1366px) {
    .image-box{
      a{
        font-size: 16rem;
      }
    }
    .text-box{
      padding-top: 0.5rem;
      p{
        margin: 0 0 3rem 0;
        padding: 0 0 0 2rem;
      }
      .btn{
        padding-left: 2.8rem;
        padding-right: 2.8rem;
      }
    }
  }
  @media all and (max-width: 1260px) {
    .image-box{
      a{
        font-size: 14rem;
      }
    }
    .text-box{
      p{
        font-size: 1.4rem;
      }
      .btn{
        padding-left: 2.2rem;
        padding-right: 2.2rem;
      }
    }
  }
}

.modal.modal-video{
  background: #212e3c;
  .videocontent{
    height:  100vh;
    margin: 0 auto;
    position: relative;
    width: 65%;
    .video-js{
      background: transparent;
      height: 36.5vw;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
    }
  }
  @media all and (max-width: 767px) {
    .videocontent{
      width: 85%;
    }
  }
}
