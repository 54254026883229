.page-rg {
  color: $white;
}

.ResponsibleLanding-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover !important;
  height: 100%;
  @media all and (min-width: 641px) {
    background: url("/files/responsible-gaming/rg-landing.jpg") no-repeat center center;
  }
  @media all and (max-width: 640px) {
    background: url("/files/responsible-gaming/rg-landing-640.jpg") no-repeat center center;
  }
}

.clearfix {
  @include clearfix();
}

.landing {
  height: 100vh;
  position: relative;
  @media #{$max-phone-menu} {
    height: calc(100vh - 4.2rem);
    margin-top: 4.2rem;
  }
  @media all and (max-width:767px) and (orientation: landscape) {
    height: 150vh;
  }

  article {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    @include clearfix();

    .data {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 12rem;
      margin: 0 auto;
      text-align: center;
      @media screen and (max-width: 1700px) {
        bottom: 2rem;
      }
      @media screen and (max-width: 640px) {
        bottom: 2rem;
      }

      h2 {
        @include fontSize(70);
        font-family: $font-family-avenir-next-lt-pro-bold;
        font-weight: 800;
        margin: 0 0 5rem;
        @media screen and (max-width: 1700px) {
          @include fontSize(40);
          margin: 0 0 2rem;
        }
        @media screen and (max-width: 640px) {
          @include fontSize(32);
          margin: 0 auto;
          width: 80%;
          text-align: center;
          line-height: 3.4rem;
        }
      }

      p {
        @include fontSize(24);
        font-family: $font-family-montserrat-light;
        font-weight: 400;
        line-height: 1.2;
        padding: 0 0 5.2rem;
        max-width: 62rem;
        margin: 0 auto;

        strong {
          font-family: "MontserratBold";
        }
        @media screen and (max-width: 1700px) {
          @include fontSize(18);
          padding: 0 0 3rem;
          max-width: 44rem;
        }
        @media screen and (max-width: 640px) {
          @include fontSize(16);
          margin: 2rem auto 0;
          width: 90%;
          text-align: center;
          line-height: 2.2rem;
          padding: 0 0 3rem;

          strong {
            display: block;
          }
        }
      }

      .ButtonSecondary {
        font-size: 1.6rem;
        padding: 2rem 4rem;
        @media screen and (max-width: 1700px) {
          font-size: 1.2rem;
          padding: 1.5rem 3rem;
        }
      }
    }
  }
}

.Responsible-banners {
  display: table;
  width: 100%;
  overflow: hidden;
  @media all and (min-width: 1025px) {
    &:hover {
      article {
        width: 15%;
      }
    }
  }

  article {
    display: table-cell;
    width: 25%;
    height: calc(100vh - 4.2rem);
    position: relative;
    cursor: pointer;
    z-index: 1;
    background-size: cover;
    transition: width .6s ease-in-out;
    width: 25%;
    overflow: hidden;

    a {
      text-decoration: none;
      color: #fff;
      cursor: pointer;
      display: block;
      width: 100%;
      height: 100%;
    }
    @media screen and (max-width: 1024px) {
      width: 50%;
      height: 65vh;
      display: block;
      float: left;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0;
      display: block;
    }
    @media all and (max-width:767px) and (orientation: landscape) {
      height: 85vh;
    }

    &:hover {}

    .bannerBg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: opacity .2s ease-in-out;
    }

    .Responsible-banners-data {
      position: absolute;
      top: 71vh;
      left: 0;
      right: 0;
      opacity: 1;
      transition: opacity .2s ease-out;
      @media screen and (max-width: 1024px) {
        top: 45vh;
      }
      @media screen and (width: 1024px) and (height: 640px) {
        top: 43vh;
      }
      @media screen and (max-width: 640px) {
        top: 42vh;
      }

      h3 {
        @include fontSize(36);
        font-family: $font-family-avenir-next-lt-pro-bold;
        font-weight: 800;
        margin: 0 5.5rem;
        text-transform: uppercase;
        position: relative;
        transition: transform 1000ms;
        transform: translateZ(0) translateY(0);
        transition: all .3s ease-out;
        text-shadow: 0 .1rem .1rem rgba(0, 0 , 0, 0.6);
        letter-spacing: .1rem;
        width: 38rem;
        @media screen and (max-width: 1700px) {
          margin: 0 3em 0 3rem;
        }
        @media screen and (min-width: 1367px) and (max-width: 1680px) {
          @include fontSize(28);
          width: 27rem;
        }
        @media screen and (max-width: 1366px) {
          @include fontSize(25);
          margin: 0 2rem;
        }
        @media screen and (min-width: 1024px) and (max-width: 1366px) {
          width: 22rem;
          @include fontSize(22);
        }
        @media screen and (max-width: 640px) {
          @include fontSize(25);
          width: 90%;
        }
        @media all and (max-width:767px) and (orientation: landscape) {
          width: 50%;
        }

        &:after {
          position: absolute;
          left: -18%;
          height: 1px;
          width: 18%;
          background: rgba(255, 255, 255, 0.3);
          content: "";
          top: -20px;
        }

        &:before {
          position: absolute;
          top: - 2rem;
          left: 0;
          height: 3px;
          width: 4rem;
          background: rgba(255, 255, 255, 1);
          content: "";
        }

        span {
          display: block;
        }
      }

      .Responsible-banners-action {
        opacity: 0;
        transform: translateZ(0) translateY(2rem);
        transition: all .5s ease-in-out .6s;
        margin: 2rem 3rem 0 7.5rem;
        @media screen and (max-width: 1700px) {
          margin-left: 5rem;
        }
        @media all and (max-width: 1366px) {
          margin-left: 4rem;
        }
        @media all and (max-width: 1024px) {
          display: block;
          transform: translateZ(0) translateY(0rem);
          margin-left: 4rem;
        }

        .icon {
          display: none;
          font-size: 28px;
          font-size: 2.8rem;
          line-height: 3.08rem;
          position: relative;
          width: 6rem;
          height: 6rem;
          text-align: center;
          -webkit-transition: all 1s ease;
          transition: all 1s ease;
          @include fontSize(28);
          border: 0;
          font-family: $font-family-avenir-next-lt-pro-bold;
          font-weight: 800;
          text-transform: uppercase;
          border: 0;
          @media all and (max-width: 1024px) {
            display: block;
          }
          @media all and (max-width: 640px) {
            display: none;
          }

          span {
            font-size: 1rem;
            position: absolute;
            left: -17px;
            top: 0;
          }
        }
      }
    }

    &.hide {
      .Responsible-banners-data {
        opacity: 0;
      }

      .bannerBg {
        opacity: .25;
      }
    }
    @media all and (min-width: 1025px) {
      &.getting-active-box {
        width: 55%;

        .Responsible-banners-data {
          //  width: 60rem;
        }

        h3 {
          transform: translateY(-10rem);
          //  width: 38rem;
          transition-delay: .6s;
        }

        .Responsible-banners-action {
          transform: translateZ(0) translateY(-10rem);
          opacity: 1;

          .icon {
            display: block;
            line-height: 65px;

            &:before {
              left: 19px;
              position: absolute;
              top: 17px;
            }
          }
        }
      }
    }
  }
}

.page-inner {
  position: relative;
  padding: 10.4rem 3rem 0 3.5rem;
  @media all and (max-width: 1700px) {
    padding-top: 8rem;
  }
  @media all and (max-width: 767px) {
    padding: 0;
  }

  &:before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url("/files/responsible-gaming/1-bg.png") no-repeat center center;
    background-size: cover;
    z-index: -1;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
  }

  article {
    margin: 0 auto;
    max-width: 110rem;
    @include clearfix();

    .ResponsibleStory-content {
      padding: 5rem 0 0 2.5rem;
      @media all and (min-width: 1280px) {
        margin-left: 32rem;
      }
      @media all and (max-width: 1279px) {
        clear: both;
      }
      @media screen and (min-width: 641px) and (max-width: 1279px) {
        .ResponsibleContent-head {
          position: absolute;
          z-index: 99;
          top: 70vh;
          width: 80%;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 0 3rem;

        .ResponsibleContent-head {
          position: absolute;
          top: 0;
          height: calc(100vh - 4.2rem);
          z-index: 2;
          width: 70%;
          bottom: 4rem;

          h2 {
            position: absolute;
            bottom: 2rem;
            left: 0;
            right: 30%;
          }
        }
      }
    }
    @media all and (min-width: 1700px) {
      max-width: 130rem;

      .ResponsibleStory-content {
        padding-top: 7.2rem;
        margin-left: 45rem;
      }
    }

    h2 {
      @include fontSize(60);
      font-family: $font-family-avenir-next-lt-pro-bold;
      font-weight: 800;
      margin: 0 0 2.5rem;
      line-height: 1;
      position: relative;
      padding: 3rem 0 0;
      text-transform: uppercase;
      @media screen and (min-width: 1280px) {
        width: 70%;
      }
      @media screen and (min-width: 1366px) {
        width: 70%;
      }
      @media screen and (max-width: 1700px) {
        @include fontSize(42);
      }
      @media screen and (max-width: 640px) {
        @include fontSize(30);
        padding: 1rem 0 0;
      }

      &:before {
        position: absolute;
        left: 0;
        height: 3px;
        width: 40%;
        background: $white;
        content: "";
        top: 0;
        z-index: 2;
        @media screen and (max-width: 640px) {
          display: none;
        }
      }

      &:after {
        position: absolute;
        left: -13%;
        width: 20%;
        height: 1px;
        background: #3f4347;
        content: "";
        top: 0;
        @media screen and (min-width: 641px) and (max-width: 1279px) {
          display: none;
        }
        @media screen and (max-width: 640px) {
          display: none;
        }
      }
    }

    p {
      @include fontSize(24);
      font-family: $font-family-montserrat-light;
      font-weight: 400;
      line-height: 1.3;
      margin: 0 0 3.125rem;
      @media screen and (max-width: 1700px) {
        @include fontSize(18);
      }
      @media screen and (max-width: 1279px) {
        position: relative;
        @include clearfix();
        margin-top: 3rem;
      }
      @media screen and (max-width: 767px) {
        @include fontSize(21);
        line-height: 3rem;
        padding: 2rem 0;

        img {
          max-width: 100%;
        }
      }

      a {
        color: #4083c9;
        cursor: pointer;
        transition: .2s color;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .small {
    @include fontSize(20);
    font-family: $font-family-montserrat-light;
    font-weight: 400;
    line-height: 2.6rem;

    &.paragraphSmallCenter {
      @include clearfix();
      max-width: 90rem;
      margin: 4rem auto;
      text-align: center;
      @media all and (max-width: 640px) {
        padding: 0 3rem;
      }
    }

    img {
      vertical-align: top;
    }

    a {
      color: #4083c9;
      cursor: pointer;
      transition: .2s color;

      &:hover {
        color: #fff;
      }
    }
  }

  .responsibleContent {
    position: relative;
  }

  .selfExclusion-image {
    max-width: 100%;
    height: auto;
    position: absolute;
    right: -35%;
    top: -15rem;
    left: 0;
    margin: 0 auto;
    @media all and (max-width: 800px) {
      max-width: 70%;
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      display: block;
    }
  }

  .responsibleQuestions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    @media screen and (max-width: 640px) {
      display: block;
    }
  }

  .responsibleQuestions-box {
    border: .1rem solid #3467a1;
    padding: 1.2rem 1rem;
    font-family: $font-family-montserrat-bold;
    font-weight: 800;
    text-align: center;
    flex: 0 30%;
    margin: 0 0 2rem;
    min-height: 110px;
    @include fontSize(14);
    line-height: 1.6rem;
    align-self: stretch;
    display: flex;
    align-items: center;
    transition: .2s box-shadow, .2s border;

    &:focus,
    &:hover {
      box-shadow: 0 0 2rem #4083C9;
      border: .1rem solid #4083C9;
    }
    @media screen and (max-width: 640px) {
      flex: 1 100%;
    }
  }

  .ResponsibleStory-banner {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      left: -15px;
      top: -15px;
      width: 15px;
      height: 120px;
      @media screen and (max-width: 640px) {
        display: none;
      }
    }

    &:after {
      position: absolute;
      content: "";
      left: 0;
      top: -15px;
      width: 105px;
      height: 15px;
      @media screen and (max-width: 640px) {
        display: none;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.pagination-story {
  padding: 10rem 0;
  @include clearfix();
  @media screen and (max-width: 640px) {
    padding: 2rem 1rem;
  }

  .pagination-wp {
    text-align: center;
    max-width: 130rem;
    margin: 0 auto;

    .storyPag {
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      position: relative;
      width: 200px;
      @media screen and (max-width: 640px) {
        width: 50%;
        display: inline-block;
        float: left;
      }

      a {
        @include fontSize(24);
        font-family: $font-family-avenir-next-lt-pro-bold;
        font-weight: 800;
        text-transform: uppercase;
        text-decoration: none;
        color: $white;
        z-index: 3;
        @media screen and (max-width: 1366px) {
          @include fontSize(20);
        }
      }

      .storyImg {
        z-index: 4;
        width: 200px;
        height: 448px;
        position: relative;
        background-size: cover;
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        -o-filter: grayscale(0%);
        @media screen and (max-width: 640px) {
          max-width: 100%;
          margin: 0 auto;
        }
      }

      .storyBg {
        -webkit-transition: all .1s ease-in;
        -moz-transition: all .1s ease-in;
        -o-transition: all .1s ease-in;
        -ms-transition: all .1s ease-in;
        @media screen and (max-width: 640px) {
          display: none;
        }
      }

      &:first-child {
        margin: 0 8rem 0 0;
        @media screen and (max-width: 640px) {
          margin: 0;
        }

        .storyImg {}

        .storyTxt {
          right: 70%;
          text-align: right;

          &:before {
            right: 0;
            @media screen and (max-width: 640px) {
              right: 1.5rem;
            }
          }
          @media screen and (max-width: 1023px) {
            right: 50%;
          }
          @media screen and (max-width: 767px) {
            text-transform: none;
            // left: 0;
            right: 0;
            // text-align: center;
          }
        }

        &:hover {
          .storyImg {
            -webkit-box-shadow: 0 15px 30px 10px rgba(20, 20, 20, 0.51);
            -moz-box-shadow: 0 15px 30px 10px rgba(20, 20, 20, 0.51);
            box-shadow: 0 15px 30px 10px rgba(20, 20, 20, 0.51);
          }

          .storyBg {
            -webkit-transform: translate(-20px, 20px);
          }
        }
      }

      &:last-child {
        margin: 0 0 2rem;
        @media screen and (max-width: 640px) {
          margin: 0;
        }

        .storyImg {
          &:hover {}
        }

        .storyTxt {
          left: 70%;
          text-align: left;

          &:before {
            left: 0;
            @media screen and (max-width: 640px) {
              left: 1.5rem;
            }
          }
          @media screen and (max-width: 1023px) {
            left: 50%;
          }
          @media screen and (max-width: 767px) {
            left: 0;
            text-transform: capitalize;

            &:before {
              left: 2rem;
            }
          }
        }

        &:hover {
          .storyImg {
            -webkit-box-shadow: 0 15px 30px 10px rgba(20, 20, 20, 0.51);
            -moz-box-shadow: 0 15px 30px 10px rgba(20, 20, 20, 0.51);
            box-shadow: 0 15px 30px 10px rgba(20, 20, 20, 0.51);
          }

          .storyBg {
            -webkit-transform: translate(20px, 20px);
          }
        }
      }

      .storyTxt {
        position: absolute;
        top: 70%;
        z-index: 4;
        width: 100%;

        &:before {
          position: absolute;
          top: -3.5rem;
          height: 3px;
          width: 4.125rem;
          background: rgba(255, 255, 255, 1);
          content: "";
          @media screen and (max-width: 767px) {
            top: -1.5rem;
            margin-right: 2rem;
          }
        }
        @media screen and (max-width: 767px) {
          top: 70%;
          @include fontSize(19);
          padding: 0 1em;
        }
      }

      &.hide {
        .storyImg {
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -o-filter: grayscale(100%);
        }
      }
    }
  }

  .storyBg {
    width: 200px;
    height: 448px;
    position: absolute;
    top: 0;
  }
}

.link {
  &.link-oval {
    border-radius: 100%;
    color: $white;
    text-decoration: none;
    transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;

    &:hover {
      span {
        transition: none;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}

.storyHome {
  .storyBg {
    background: #4083c9;
  }
}

.ResponsibleStory-banner {
  background-size: cover;
  background-position: center right;
  height: 81vh;
  float: left;
  width: 40rem;
  z-index: 2;
  @media all and (min-width: 641px) and (max-width: 1279px) {
    background-position: center center;
  }
  @media screen and (max-width: 1700px) {
    width: 30rem;
  }
  @media all and (max-width: 1279px) {
    background-size: cover;
    z-index: 1;
    position: fixed;
    width: 100%;
  }
  @media all and (max-width: 1024px) {
    height: calc(100vh - 10rem);
  }
  @media all and (max-width: 767px) {
    height: calc(100vh - 4.2rem);
    margin-top: 4.2rem;
  }
}

.storyOne-banner {
  background-image: url("/files/responsible-gaming/1-m.jpg");
  @media all and (max-width: 640px) {
    background-image: url("/files/responsible-gaming/1-640.jpg");
  }
  @media all and (min-width: 641px) and (max-width: 1279px) {
    background-image: url("/files/responsible-gaming/1.jpg");
  }

  &:after,
  &:before {
    background: #8a37e6;
  }
}

.storyTwo-banner {
  background-image: url("/files/responsible-gaming/2-m.jpg");
  @media all and (max-width: 640px) {
    background-image: url("/files/responsible-gaming/2-640.jpg");
  }
  @media all and (min-width: 641px) and (max-width: 1279px) {
    background-image: url("/files/responsible-gaming/2.jpg");
  }

  &:after,
  &:before {
    background: #00ae75;
  }
}

.storyThree-banner {
  background-image: url("/files/responsible-gaming/3-m.jpg");
  @media all and (max-width: 640px) {
    background-image: url("/files/responsible-gaming/3-640.jpg");
  }
  @media all and (min-width: 641px) and (max-width: 1279px) {
    background-image: url("/files/responsible-gaming/3.jpg");
  }

  &:after,
  &:before {
    background: #dc0c3f;
  }
}

.storyFour-banner {
  background-image: url("/files/responsible-gaming/4-m.jpg");
  @media all and (max-width: 640px) {
    background-image: url("/files/responsible-gaming/4-640.jpg");
  }
  @media all and (min-width: 641px) and (max-width: 1279px) {
    background-image: url("/files/responsible-gaming/4.jpg");
  }

  &:after,
  &:before {
    background: #4083c9;
  }
}

.storyOne {
  .link {
    &:hover {
      background: #8a37e6;
      border: 1px solid #8a37e6;
    }
  }

  .storyBg {
    background: #8a37e6;
  }
}

.storyTwo-banner {
  &:after,
  &:before {
    background: #00ae75;
  }
}

.storyTwo {
  .link {
    &:hover {
      background: #00ae75;
      border: 1px solid #00ae75;
    }
  }

  .storyBg {
    background: #00ae75;
  }
}

.storyThree-banner {
  &:after,
  &:before {
    background: #dc0c3f;
  }
}

.storyThree {
  .link {
    &:hover {
      background: #dc0c3f;
      border: 1px solid #dc0c3f;
    }
  }

  .storyBg {
    background: #dc0c3f;
  }
}

.storyFour-banner {
  &:after,
  &:before {
    background: #4083c9;
  }
}

.storyFour {
  .link {
    &:hover {
      background: #4083c9;
      border: 1px solid #4083c9;
    }
  }

  .storyBg {
    background: #4083c9;
  }
}

.Responsible-url-list {
  width: 32%;
  padding-left: 0;
  @media screen and (max-width: 1279px) {
    width: 100%;
    text-align: center;
  }

  li {
    display: block;
    list-style: none;
    padding: 16px 0;
    // border-bottom: 1px solid #057f5a;
    a {
      @include fontSize(20);
      font-family: $font-family-montserrat-light;
      font-weight: 400;
      color: $white;
      text-decoration: none;
      position: relative;
      transition: .2s color;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        height: 1px;
        background: $color-primary;
        transition: .2s height;
      }

      &:hover {
        color: $color-primary;

        &:after {
          height: 3px;
        }
      }
    }
  }
}

.box-data {
  display: flex;
  margin: 7rem 2rem 4.5rem 10rem;
  @include clearfix();
  @media screen and (min-width: 640px) and (max-width: 1024px) {
    margin: 10rem auto 4.5rem;
    width: 100%;
  }

  h3 {
    @include fontSize(20);
    margin: 0 0 3.5rem;
    font-family: $font-family-montserrat-bold;
    font-weight: 800;
    color: $color-primary;
    @media screen and (max-width: 640px) {
      margin: 2rem 0;
      text-align: left;
    }
  }

  ul {
    padding-left: 0;

    li {
      display: block;
      padding: 1rem 0;
      @include fontSize(16);
      font-family: $font-family-montserrat-light;
      font-weight: 400;

      &:last-child {
        padding-bottom: 1rem;
      }
      @media screen and (max-width: 640px) {
        @include fontSize(16);
      }

      a {
        text-decoration: none;
        color: $white;
        transition: .2s color;

        &:hover {
          color: $color-secondary;
        }
      }

      span {
        color: $color-primary;
        font-family: $font-family-avenir-next-lt-pro-bold;
        font-weight: 800;
        @include fontSize(12);
        line-height: 2.3;
        text-transform: uppercase;
        width: 8rem;
        padding: 0 2rem 0 0;
        float: left;
        @media screen and (max-width: 640px) {
          float: none;
          display: block;
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    display: block;
    margin: 0;
    text-align: center;

    img {
      max-width: 50%;
      height: auto;
    }

    ul {
      padding: 0;
      border-left: 0;
      border-bottom: 1px solid #00ae75;
      text-align: left;
      margin: 0 0 2rem;
    }
  }
  @media all and (min-width: 1700px) {
    margin: 10rem 4rem 4.5rem 14rem;

    h3 {
      @include fontSize(24);
      margin: 0 0 4.5rem;
    }

    ul li {
      @include fontSize(20);
    }
  }
}

.box-data-row-2 {
  position: relative;
  padding: 0 2.5rem 0 2rem;
  width: 100%;
  clear: both;
  @media screen and (max-width: 640px) {
    margin: 0;
    width: 100%;
    padding: 0;
  }

  .box-data {
    width: 37.5%;
    float: left;
    margin: 10rem 1rem 5rem;
    @media screen and (min-width: 641px) and (max-width: 1024px) {
      margin: 10rem auto 4.5rem;
      width: 100%;
    }
    @media screen and (min-width: 1025px) and (max-width: 1279px) {
      margin: 10rem 5rem 4.5rem 10rem;
      width: 100%;
    }
    @media screen and (max-width: 640px) {
      padding: 0 3rem;
      width: 100%;
      margin: 0;
    }
    @media screen and (min-width: 1700px) {
      &:first-child {
        width: 44.5%;
      }
    }
  }
}

.box-data-image {
  text-align: right;
  padding: 0 3rem 0 0;
  @media screen and (min-width: 641px) and (max-width: 1365px) {
    padding: 0 2rem 0 0;
  }

  span {
    font-size: 7rem;
    vertical-align: top;
    // @media screen and (min-width: 641px) and (max-width: 1365px) {
    //   font-size: 8rem;
    // }
    @media screen and (max-width: 640px) {
      vertical-align: bottom;
      padding: 0;
    }
  }
  @media screen and (max-width: 640px) {
    text-align: center;
    display: none;
    margin-top: 2rem;

    span {
      font-size: 10rem;
    }
  }
  @media screen and (min-width: 1700px) {
    span {
      font-size: 10rem;
    }
  }
}

.box-data-txt {
  padding: 0 0 0 3rem;
  border-left: 1px solid $color-primary;
  @media screen and (min-width: 641px) and (max-width: 1365px) {
    padding: 0 0 0 1.5rem;
  }
  @media screen and (max-width: 640px) {
    border-left: 0;
    padding: 0;
  }
}

.box-data-image,
.box-data-txt {
  display: inline-block;
  float: left;
  @media screen and (max-width: 640px) {
    display: block;
    float: none;
  }
}

.BitwinCareer-landscape {
  display: none;
}
/*
@media all and (max-width:767px) and (orientation: landscape) {
  .page {
    display: none;
  }
  .BitwinCareer-landscape {
    display: block;
    color: #fff;
    position: relative;
    height: 100vh;
    background: url("/files/responsible-gaming/Portrait-Orientation-640.jpg") no-repeat center center;
    background-size: cover;
    .BitwinCareer-landscape-txt {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 9vh;
      margin: 0 auto;
      text-align: center;
      text-transform: capitalize;
      letter-spacing: 0.04rem;
        font-size: 2.4rem;
        font-family: $font-family-avenir-next-lt-pro-bold;
        font-weight: 800;
        line-height: 1;
        span {
          display: block;
          font-family: $font-family-montserrat-light;
          font-weight: 400;
          font-size: 1.4rem;
          text-transform: lowercase;
          line-height: 2rem;
          letter-spacing: 0.03rem;
        }
    }
  }
}
*/
.page-rg-3 {
  .small {
    width: 37rem;
    // float: left;
    @media screen and (max-width: 640px) {
      width: 100%;
      @include clearfix();
    }
    @media screen and (max-width: 480px) {
      padding: 0;
    }
    @media screen and (min-width: 641px) and (max-width: 800px) {
      width: 65%;
    }
    @media screen and (min-width: 801px) and (max-width: 1279px) {
      width: 40%;
    }
  }
}

.ResponsibleContent-paragraph {
  padding: 0 0 8rem;
  @media all and (max-width: 1279px) {
    padding: 0;
  }
}
@keyframes animatedBackground {
  0%,
  100% {
    transform: translate(0, 0);
    transform: scale(1);
  }

  50% {
    transform: translate(0, -4rem);
    transform: scale(1.25);
  }
}

.Responsible-modal-article {
  display: flex;
  height: 100vh;

  .col-image {
    height: 100vh;
    position: relative;
    padding-right: 44vh;
    background-position: center center;
    background-size: cover;
    @media screen and (max-width: 1200px) {
      padding-right: 22vh;
    }
    @media screen and (max-width: 760px) {
      display: none;
    }
  }

  .col-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h3 {
    @include fontSize(60);
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-weight: 800;
    margin: 0;
    text-align: left;
    line-height: 1;

    span {
      display: block;
      color: $color-primary;
    }
    @media screen and (max-width: 1700px) {
      font-size: 4rem;
    }
  }

  p {
    @include fontSize(22);
    font-family: $font-family-montserrat-light;
    font-weight: 400;
    line-height: 1.3;
    margin: 0 0 3.125rem;

    span {
      color: $color-primary;
    }
    @media screen and (max-width: 1700px) {
      font-size: 1.6rem;
    }
  }

  img {
    margin: 0 1rem 0 0;
  }

  .content-header {
    padding: 4rem 10rem;
    @media screen and (max-width: 1700px) {
      padding: 4rem 6.25rem;
    }
    @media screen and (max-width: 1200px) {
      padding: 4rem 3rem;
    }
    @media screen and (max-width: 740px) {
      padding: 2rem 3rem 1rem;
    }
  }

  .content-body {
    padding: 0 10rem 4rem;
    @media screen and (max-width: 1700px) {
      padding: 0 6.25rem 4rem;
    }
    @media screen and (max-width: 1200px) {
      padding: 4rem 3rem;
    }
    @media screen and (max-width: 740px) {
      padding: 1rem 3rem;
    }
  }
}
