.modal-background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.94);
  opacity: 1;
  z-index: 997;
}

.modal {
  display: none;
  height: 100vh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 998;
  padding: 0;
}

.modal-close {
  color: $color-primary;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 1rem 0;
  z-index: 9999;
  transition: .3s color, .3s background;
  &:hover {
    background: $color-primary;
    color: $white;
  }
  .icon {
    margin: 0 1.3rem;
    font-size: 2.3rem;
  }
}

.modal-background.active, .modal.active {
  display: block;
}

.modal-scroll {
  display: flex !important;
  flex-direction: column;
}
