@charset "UTF-8";


@font-face {
  font-family:'icons';
  src: url('./fonts/icons.eot');
  src: url('./fonts/icons.eot?#iefix') format('embedded-opentype'),
  url('./fonts/icons.woff') format('woff'),
  url('./fonts/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-18:before {
  content: "\61";
}
.icon-bitcoin-guide-logo:before {
  content: "\62";
}
.icon-bitwin-chat:before {
  content: "\63";
}
.icon-chat:before {
  content: "\64";
}
.icon-email:before {
  content: "\65";
}
.icon-full-screen-close:before {
  content: "\66";
}
.icon-minus:before {
  content: "\67";
}
.icon-music-off:before {
  content: "\68";
}
.icon-music-on:before {
  content: "\69";
}
.icon-play:before {
  content: "\6a";
}
.icon-play-without-circle:before {
  content: "\6b";
}
.icon-plus:before {
  content: "\6c";
}
.icon-promotions-logo:before {
  content: "\6d";
}
.icon-neteller:before {
  content: "\73";
}
.icon-facebook:before {
  content: "\74";
}
.icon-bitcoin-accept:before {
  content: "\75";
}
.icon-bitcoin:before {
  content: "\76";
}
.icon-e-sports:before {
  content: "\77";
}
.icon-arrow-up:before {
  content: "\79";
}
.icon-arrow-slider-right:before {
  content: "\7a";
}
.icon-cross:before {
  content: "\41";
}
.icon-check:before {
  content: "\42";
}
.icon-arrow-slider-left:before {
  content: "\43";
}
.icon-arrow-right:before {
  content: "\44";
}
.icon-vip-club:before {
  content: "\46";
}
.icon-visa:before {
  content: "\47";
}
.icon-rating-outline:before {
  content: "\48";
}
.icon-rating:before {
  content: "\49";
}
.icon-scroll-hold-1:before {
  content: "\4a";
}
.icon-scroll-hold-2:before {
  content: "\4b";
}
.icon-search:before {
  content: "\4c";
}
.icon-skrill:before {
  content: "\4d";
}
.icon-mastercard:before {
  content: "\4e";
}
.icon-linkedin:before {
  content: "\4f";
}
.icon-menu:before {
  content: "\50";
}
.icon-arrow-breadcrummbs:before {
  content: "\51";
}
.icon-bitwin-sign:before {
  content: "\52";
}
.icon-full-screen:before {
  content: "\53";
}
.icon-favorite:before {
  content: "\54";
}
.icon-bonuses:before {
  content: "\55";
}
.icon-instagram:before {
  content: "\58";
}
.icon-last-play:before {
  content: "\59";
}
.icon-chat-1:before {
  content: "\5a";
}
.icon-casino-live:before {
  content: "\30";
}
.icon-arrow-expand-up:before {
  content: "\31";
}
.icon-arrow-left:before {
  content: "\32";
}
.icon-arrow-expand-down:before {
  content: "\33";
}
.icon-arrow-down:before {
  content: "\34";
}
.icon-arrow-input:before {
  content: "\35";
}
.icon-info:before {
  content: "\57";
}
.icon-paypal:before {
  content: "\36";
}
.icon-info-fill:before {
  content: "\37";
}
.icon-dot:before {
  content: "\38";
}
.icon-calendar:before {
  content: "\56";
}
.icon-casino:before {
  content: "\39";
}
.icon-bank-transfer:before {
  content: "\21";
}
.icon-user-chat:before {
  content: "\6e";
}
.icon-user:before {
  content: "\6f";
}
.icon-twitter:before {
  content: "\70";
}
.icon-sports:before {
  content: "\71";
}
.icon-sound-volume:before {
  content: "\72";
}
.icon-skype:before {
  content: "\45";
}
.icon-arrow-light:before {
  content: "\22";
}
.icon-simple-view:before {
  content: "\23";
}
.icon-modern-view:before {
  content: "\24";
}
.icon-bitbay:before {
  content: "\25";
}
.icon-bitfinex:before {
  content: "\26";
}
.icon-bitstamp:before {
  content: "\27";
}
.icon-blockchain:before {
  content: "\28";
}
.icon-circle:before {
  content: "\29";
}
.icon-coinbase:before {
  content: "\2a";
}
.icon-poloniex:before {
  content: "\2b";
}
.icon-gamblers-anonymous:before {
  content: "\2c";
}
.icon-gambling-therapy:before {
  content: "\2d";
}
.icon-game-care:before {
  content: "\2e";
}
.icon-curacao-egaming:before {
  content: "\a3";
}
.icon-netent-color:before {
  content: "\3b";
}
.icon-netent-mono:before {
  content: "\3c";
}
.icon-live-logo:before {
  content: "\3d";
}
.icon-evolution-gaming-mono:before {
  content: "\3e";
}
.icon-evolution-gaming-color:before {
  content: "\3f";
}
.icon-microgaming-color:before {
  content: "\2f";
}
.icon-microgaming-mono:before {
  content: "\3a";
}
.icon-arrow-thin-right:before {
  content: "\40";
}
.icon-arrow-scroll-dwon:before {
  content: "\5b";
}
.icon-arrow-thin-left:before {
  content: "\5d";
}
.icon-vip-club-logo:before {
  content: "\5e";
}
.icon-frame-benefits:before {
  content: "\5f";
}
.icon-dots:before {
  content: "\78";
}
.icon-ezugiblack:before {
  content: "\60";
}
.icon-ezugiwhite:before {
  content: "\7b";
}
.icon-my-activity:before {
  content: "\7c";
}
.icon-my-funds:before {
  content: "\7d";
}
.icon-my-profile:before {
  content: "\7e";
}
.icon-responsible:before {
  content: "\5c";
}
.icon-special-offers:before {
  content: "\e000";
}
