/* Variables */

/* Colors */

$mainBackground: #000;
$menuBackground: #000;
$topBarSmall: #000;
$headerBg: #000;
$color-primary: #00ae75;
$color-secondary: #4083c9;
$hoverGreen: #038056;
$white: #ffffff;
$black: #000000;
$grey: #6c7580;
$himalaya: #6c5d18;
$darkerGrey: #1f2a34;
$darkGrey: #38414b;
$red: #e02625;
$greenBorder: #00ae75;
$scrollBackground: #1d2933;
$scrollMain: #334350;
$casinoBg: #0e181e;
$overlayBlack: #020406;
$overlayBlackBg: rgba(#000000, 0.64);
$overlayGreen: rgba(#002519, 0.9);
$overlayBlue: rgba(#0d1f33, 0.9);
$overlayLightBlue: rgba(#1d2933, 0.7);
$overlayWhite: rgba(white, 0.14);
$gameMenuGrey: #353537;
$gameButtonsBg: #162129;
$color-orange: #ff9900;
$dark-gold: #caa85e;
$gameListBg: #0f181f;
$blackHover: #070b0d;
$footerBackground: #060A0D;
$footerBackground-sm: #111519;
$blueGameMode: #0d253d;
$darkBlueGameMode: #0d0e17;
$greyGameMode: #3d434f;
$accountMenuBG: #090c11;
$accountMenuHover: #0d1317;
$accountScrollThumb: #212a30;
$accountScrollTrack: #141c21;
$lightGrey: #B090A9;
$listOddBg: #192127;
$grayMyAccount: #353a40;
$dayPickerBg: #182127;
$daySelectedBg: #243d55;
$greyBorder: #535e6c;
$myAccountActiveBlue: #2d6197;
$datePickerBorder: #28323a;
$lightGrayText: #515860;
$red: #d72a2a;
$outsideDays: #323a40;
$weekDays: #646b74;
$accountOverviewBG: #0a0e13;
$accountGrey: #2f3640;
$accountBorder: #2b3139;
$accountGradientCenter: #073b2f;
$accountScrollDot: #414e56;
$color-secondary-gradient: #23456d;
$color-primary-gradient: #01724d;
$color-secondary-radial-gradient: #1e4265;
$color-primary-stroke: #00ad73;
$userInfoRed: #c13c3c;
$goBackColor: #2c3a49;
$bannerGray: #646668;
$supportMenuBorder: #36373d;
$supportHeader: #656c74;
$accordinItemBg: #19222a;
$viewModeColor: #4e5862;
$viewModeBorder: #212b33;
$liveCasinoBg: #1b0d1f;
$signInGradient: linear-gradient(10deg, rgba($color-primary, 0.9), rgba(#0b0b13, 0.9) 80%);
$bonusGradientGreen: #063d2e;
$bonusContentGradient: #031511;
$bitcoinHeaderColor: #687283;
$notifyGrey: #3f4750;
$bronze: #8c7d65;
$silver: #7f868b;
$gold: #d9b06f;
$platinum: #7c96ac;
$loyaltyOverviewBg: #0a0e13;
$loyaltyColor: #b9b9ba;
$termsAndConditionsBg: #0d1317;
$termsAndConditionsItemBg: #273440;
$termsAndConditionsActiveItemBg: #141c21;
$promoBorder: #101b26;
$promoHoverBg: #090f14;
/* Fonts */

$mainText: #65696C;
$greenText: #00ac73;

$green-secondary-text: #04ad74;

/* Fonts */

$defaultFontSize: 14px;
$fontBold: 700;
$fontSemiBold: 500;
$fontNormal: 400;
$fontLight: 300;
$tracking15: 0.015em;

/* Sizes */

$headerHeight: 4.2rem;
$defaultFontSize: 14px;
$casinoBorderRadius: 1.5rem;
$scrollWidth: 1.1rem;
$topHeaderAccountHeight: 6.2rem;
$casinoPadding: 2rem;
$casinoPaddingLg: 3.4rem;
$slidingHeaderHeight: 8.5rem;
$about-us-header: 3.5rem;
/* mixins defaults */

$tileColor: $color-primary;
$tileButtonHeight: 4.5rem;
$tileButtonWidth: 15rem;
$tileButtonWidthBig: 18rem;
$tileButtonHeightLg: 6rem;
$tileButtonWidthLg: 19rem;
$tileButtonWidthBigLg: 24rem;
$tileCenterWidth: 64%;
$tileCenterHeight: 17rem;
$tileVerticalPadding: 0%;
$tileRateTop: 3rem;
$tileButtonPadding: 1.6rem;
$tileButtonPaddingLg: 2.4rem;

/* Screens */

$width-600: "screen and (min-width: 600px)";
$width-640: "screen and (min-width: 640px)";
$width-760: "screen and (min-width: 760px)";
$width-1000: "screen and (min-width: 1000px)";
$width-1200: "screen and (min-width: 1200px)";
$width-1400: "screen and (min-width: 1400px)";
$width-1580: "screen and (min-width: 1580px)";
$width-1600: "screen and (min-width: 1600px)";
$width-1700: "screen and (min-width: 1700px)";
$width-1920: "screen and (min-width: 1920px)";

$max-width-640: "screen and (max-width: 640px)";
$max-width-760: "screen and (max-width: 760px)";
$max-width-1000: "screen and (max-width: 1000px)";
$max-width-1200: "screen and (max-width: 1200px)";
$max-width-1400: "screen and (max-width: 1400px)";
$max-width-1600: "screen and (max-width: 1600px)";
$max-width-1700: "screen and (max-width: 1700px)";

$tablet-menu: "screen and (min-width: 1240px)";
$phone-menu: "screen and (min-width: 940px)";

$max-tablet-menu: "screen and (max-width: 1240px)";
$max-phone-menu: "screen and (max-width: 940px)";

/* Others */
$transitionParams: cubic-bezier(0.5, 0, 0.3, 1.5) 0.5s;
$documentMinMargin: 50px;
$accountMenuWidth: 18.7rem;
$promotionsWidth: 93rem;
$promotionsSmallerWidth: 88rem;

/* From Krzysiek */
$gold: #cab23f;
$gold-alpha: rgba(202, 178, 63, .5);

$font-family-avenir-next-lt-pro: "AvenirNextLTProRegular", Arial, sans-serif;
$font-family-avenir-next-lt-pro-i: "AvenirNextLTProIt", Arial, sans-serif;
$font-family-avenir-next-lt-pro-bold: "AvenirNextLTProBold", Arial, sans-serif;
$font-family-avenir-next-lt-pro-demi: "AvenirNextLTProDemi", Arial, sans-serif;
$font-family-montserrat-light: "MontserratLight", Arial, serif;
$font-family-montserrat-regular: "Montserrat", Arial, serif;
$font-family-montserrat-bold: "MontserratBold", Arial, serif ;
$font-family-base: $font-family-avenir-next-lt-pro-bold;

$font-size-base: 10px !default;

/* vip page swiper */
$swiper-width-md: 90vw;
$swiper-width: 77vw;
$swiper-width-lg: 71vw;
$swiper-width-xl: 53vw;
$swiper-width-xxl: 1017;

$swiper-img-height: 400px;
$swiper-img-height-lg: 400px;
$swiper-img-height-xl: 500px;
