$path: './fonts';

@font-face {
  font-family:'AvenirNextLTProBold';
  src: url($path + '/AvenirNextLTPro-Bold.eot');
  src: url($path + '/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
  	url($path + '/AvenirNextLTPro-Bold.woff') format('woff'),
  	url($path + '/AvenirNextLTPro-Bold.svg#AvenirNextLTProBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AvenirNextLTProRegular';
  src: url($path + '/AvenirNextLTPro-Regular.eot');
  src: url($path + '/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-Regular.woff') format('woff'),
    url($path + '/AvenirNextLTPro-Regular.svg#AvenirNextLTProRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AvenirNextLTProIt';
  src: url($path + '/AvenirNextLTPro-It.eot');
  src: url($path + '/AvenirNextLTPro-It.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-It.woff') format('woff'),
    url($path + '/AvenirNextLTPro-It.svg#AvenirNextLTProIt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AvenirNextLTProBoldCn';
  src: url($path + '/AvenirNextLTPro-BoldCn.eot');
  src: url($path + '/AvenirNextLTPro-BoldCn.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-BoldCn.woff') format('woff'),
    url($path + '/AvenirNextLTPro-BoldCn.svg#AvenirNextLTProBoldCn') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AvenirNextLTProBoldCnIt';
  src: url($path + '/AvenirNextLTPro-BoldCnIt.eot');
  src: url($path + '/AvenirNextLTPro-BoldCnIt.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-BoldCnIt.woff') format('woff'),
    url($path + '/AvenirNextLTPro-BoldCnIt.svg#AvenirNextLTProBoldCnIt') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family:'AvenirNextLTProCn';
  src: url($path + '/AvenirNextLTPro-Cn.eot');
  src: url($path + '/AvenirNextLTPro-Cn.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-Cn.woff') format('woff'),
    url($path + '/AvenirNextLTPro-Cn.svg#AvenirNextLTProCn') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AvenirNextLTProCnIt';
  src: url($path + '/AvenirNextLTPro-CnIt.eot');
  src: url($path + '/AvenirNextLTPro-CnIt.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-CnIt.woff') format('woff'),
    url($path + '/AvenirNextLTPro-CnIt.svg#AvenirNextLTProCnIt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AvenirNextLTProDemi';
  src: url($path + '/AvenirNextLTPro-Demi.eot');
  src: url($path + '/AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-Demi.woff') format('woff'),
    url($path + '/AvenirNextLTPro-Demi.svg#AvenirNextLTProDemi') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AvenirNextLTProDemiIt';
  src: url($path + '/AvenirNextLTPro-DemiIt.eot');
  src: url($path + '/AvenirNextLTPro-DemiIt.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-DemiIt.woff') format('woff'),
    url($path + '/AvenirNextLTPro-DemiIt.svg#AvenirNextLTProDemiIt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AvenirNextLTProDemiCn';
  src: url($path + '/AvenirNextLTPro-DemiCn.eot');
  src: url($path + '/AvenirNextLTPro-DemiCn.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-DemiCn.woff') format('woff'),
    url($path + '/AvenirNextLTPro-DemiCn.svg#AvenirNextLTProDemiCn') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AvenirNextLTProDemiCnIt';
  src: url($path + '/AvenirNextLTPro-DemiCnIt.eot');
  src: url($path + '/AvenirNextLTPro-DemiCnIt.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-DemiCnIt.woff') format('woff'),
    url($path + '/AvenirNextLTPro-DemiCnIt.svg#AvenirNextLTProDemiCnIt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'AvenirNextLTProHeavyCn';
  src: url($path + '/AvenirNextLTPro-HeavyCn.eot');
  src: url($path + '/AvenirNextLTPro-HeavyCn.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-HeavyCn.woff') format('woff'),
    url($path + '/AvenirNextLTPro-HeavyCn.svg#AvenirNextLTProHeavyCn') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTProHeavyCnIt';
  src: url($path + '/AvenirNextLTPro-HeavyCnIt.eot');
  src: url($path + '/AvenirNextLTPro-HeavyCnIt.eot?#iefix') format('embedded-opentype'),
    url($path + '/AvenirNextLTPro-HeavyCnIt.woff') format('woff'),
    url($path + '/AvenirNextLTPro-HeavyCnIt.svg#AvenirNextLTProHeavyCnIt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url($path + '/Montserrat.eot');
  src: url($path + '/Montserrat.eot?#iefix') format('embedded-opentype'),
    url($path + '/Montserrat.woff') format('woff'),
    url($path + '/Montserrat.svg#Montserrat') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratLight';
  src: url($path + '/Montserrat-Light.eot');
  src: url($path + '/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
    url($path + '/Montserrat-Light.woff') format('woff'),
    url($path + '/Montserrat-Light.svg#MontserratLight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: url($path + '/Montserrat-Bold.eot');
  src: url($path + '/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url($path + '/Montserrat-Bold.woff') format('woff'),
    url($path + '/Montserrat-Bold.svg#MontserratBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBlack';
  src: url($path + '/Montserrat-Black.eot');
  src: url($path + '/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
    url($path + '/Montserrat-Black.woff') format('woff'),
    url($path + '/Montserrat-Black.svg#MontserratBlack') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratHairline';
  src: url($path + '/Montserrat-Hairline.eot');
  src: url($path + '/Montserrat-Hairline.eot?#iefix') format('embedded-opentype'),
    url($path + '/Montserrat-Hairline.woff') format('woff'),
    url($path + '/Montserrat-Hairline.svg#MontserratHairline') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlayFairDisplayBlack';
  src: url($path + '/Playfair-Display-Black.eot');
  src: url($path + '/Playfair-Display-Black.eot?#iefix') format('embedded-opentype'),
    url($path + '/Playfair-Display-Black.woff') format('woff'),
    url($path + '/Playfair-Display-Black.svg#PlayfairDisplayBlack') format('svg');
  font-weight: normal;
  font-style: normal;
}
