.nicescroll-rails {
  z-index: 100;
}

.nicescroll-cursors {
  color: $white;
  text-align: center;

  &:after {
      font-family: "icons" !important;
      content: "K";
      position: absolute;
      top: 50%;
      font-size: 2rem;
      transform: translate(-50%, -50%);
  }
}
