$background: #3d525b;
$foreground: #ffffff;
$size: 73%;
$anim: 300ms;

@mixin scrollindicator () {
  width: 1px;
  height: $size;
  display: block;
  border: none;
  z-index: 29;
  background: $background;
  color: $foreground;
}

.scrollindicator {
  @include scrollindicator();
}

progress.scrollindicator,
div.scrollindicator {
  position: fixed;
  top: 11.71rem;
  right: 2.938em;
  bottom: 2.938em;
  height: auto;
}

progress.scrollindicator {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-progress-bar {
    background-color: transparent;
  }

  &::-webkit-progress-value {
    background-color: $foreground;
  }

  &::-moz-progress-bar {
    background-color: $foreground;
  }
}

.scrollindicator-nohtml5 {
  @include scrollindicator();
  span {
    width: 2px;
    display: block;
    text-indent: -9999px;
    background: $foreground;
    position: absolute;
    right: 0;
  }

  .animated & span {
    -webkit-transition: width $anim ease;
    transition: width $anim ease;
  }
}