.PageFooter {
  color: white;
  font-size: 1.4rem;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: 1s all cubic-bezier(0.27, 0.38, 0.24, 1.08);
  -o-transition: 1s all cubic-bezier(0.27, 0.38, 0.24, 1.08);
  transition: 1s all cubic-bezier(0.27, 0.38, 0.24, 1.08);
}

.PageFooter--folded {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
}

.PageFooter-content {
  padding: 6rem 2.5rem 0;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.9);
}

.PageFooter-TopSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.PageFooter-InfoSection {
  margin-top: 1rem;
}

.PageFooter-Tabs {
  min-height: 45rem;
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.PageFooter--fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 30;
}

.PageFooter--fixed .InfoSection {
  background: transparent;
}

.PageFooter--fixed .PageFooter-content {
  padding: 2.5rem 1.5rem 0 2.5rem;
}
@media screen and (min-width: 1000px) {
  .PageFooter-Tabs {
    background-image: url("/img/footer-tab-bkg.png");
  }
}
@media (max-width: 999px) {
  .PageFooter {
    padding-bottom: 2rem;
  }
}
@media (min-width: 1250px) {
  .PageFooter-TopSection {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .PageFooter-InfoSection {
    margin-top: 5.5rem;
  }
}

.WorkInProgress {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  opacity: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.WorkInProgress.WorkInProgress-fadeIn {
  opacity: 1;
  -webkit-transition: all 2s ease-in;
  -o-transition: all 2s ease-in;
  transition: all 2s ease-in;
}

.WorkInProgress img {
  width: 70vw;
  height: 70vh;
}
@media (min-width: 600px) {
  .FooterMenus {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .FooterMenus-Menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0.5;
    -webkit-flex: 0.5;
    -ms-flex: 0.5;
    flex: 0.5;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.HorizontalMenuList {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
}

.HorizontalMenuList-ListItem {
  margin-left: 0;
  margin-bottom: 1.7rem;
  font-size: 1.6rem;
}

.HorizontalMenuList-ListItemLink {
  color: white;
  text-decoration: none;
  font-family: 'AvenirNextLTProRegular';
  white-space: nowrap;
}

.HorizontalMenuList-ListItemLink--big {
  font-family: 'AvenirNextLTProBold';
  text-transform: uppercase;
}

.HorizontalMenuList-ListItemLink:hover {
  text-decoration: underline;
  color: #00ae75;
}
@media (max-width: 599px) {
  .HorizontalMenuList--big {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .HorizontalMenuList--big .HorizontalMenuList-ListItem {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 50%;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
  }
}
@media (min-width: 600px) {
  .HorizontalMenuList-ListItem {
    text-align: center;
  }
}
@media screen and (min-width: 640px) {
  .HorizontalMenuList-ListItem {
    margin-bottom: 2.5rem;
  }
}
@media screen and (min-width: 1000px) {
  .FooterMenus {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .FooterMenus-Menu--top {
    margin-bottom: 4rem;
  }

  .HorizontalMenuList {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .HorizontalMenuList-ListItem {
    margin-bottom: 0;
    font-size: 1.4rem;
    margin-left: 2rem;
  }

  .HorizontalMenuList-ListItem:first-child {
    margin-left: 0;
  }
}
@media (min-width: 1250px) {
  .FooterMenus {
    -webkit-box-flex: 0.7;
    -webkit-flex: 0.7;
    -ms-flex: 0.7;
    flex: 0.7;
    margin-top: 0;
  }
}
@media screen and (min-width: 1400px) {
  .HorizontalMenuList-ListItem {
    margin-left: 4rem;
  }

  .HorizontalMenuList-ListItem--big {
    margin-left: 4.5rem;
  }
}
@media screen and (min-width: 1600px) {
  .HorizontalMenuList-ListItem {
    font-size: 1.4rem;
    margin-left: 3rem;
    letter-spacing: 1px;
    line-height: 1;
  }

  .HorizontalMenuList-ListItem--big {
    letter-spacing: 2px;
    margin-left: 3rem;
  }
}

.SocialSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.SocialSection-SocialIconsPosition {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.SocialSection-Locale {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.SocialSection-Contact {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.SocialSection-SocialIcons {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  margin-top: 1.7rem;
}

.SocialSection-ContactButton {
  font-size: 1.2rem;
  height: 3rem;
  padding: .8rem 1.5rem;
  min-height: auto;
  color: #FFF;
  white-space: nowrap;
  line-height: 1;

  @media (min-width: 1700px) {
    font-size: 1.4rem;
    height: 3.5rem;
    line-height: 1.6rem;
    padding: .8rem 2.5rem;
  }
}

@media (max-width: 639px) {
  .SocialSection-Locale {
    padding-right: 1rem;
  }

  .SocialSection-Locale .LocaleSelect .SelectField .Select {
    width: 100% !important;
  }

  .SocialSection-Locale .LocaleSelect .SelectField .Select .Select-control {
    width: 100% !important;
  }

  .SocialSection-Contact {
    padding-left: 1rem;
  }

  .SocialSection-ContactButton {
    display: block;
    width: 100%;
  }
}
@media screen and (min-width: 640px) {
  .SocialSection {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 3.5rem;
  }

  .SocialSection-Locale {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-flex: 0.6;
    -webkit-flex: 0.6;
    -ms-flex: 0.6;
    flex: 0.6;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .SocialSection-Contact {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .SocialSection-SocialIcons {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
    margin-top: 0;
  }
}
@media (min-width: 1250px) {
  .SocialSection {
    -webkit-box-flex: 0.4;
    -webkit-flex: 0.4;
    -ms-flex: 0.4;
    flex: 0.4;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .SocialSection-SocialIconsPosition {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .SocialSection-Locale {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 3;
    -webkit-flex: 3;
    -ms-flex: 3;
    flex: 3;
  }

  .SocialSection-Contact {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 17rem;
  }

  .SocialSection-ContactButton {
    margin-left: 2.5rem;
  }

  .SocialSection-SocialIcons {
    margin-top: 4rem;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    text-align: right;
    width: 100%;
  }
}
@media screen and (min-width: 1400px) {
  .SocialSection-Contact {
    min-width: 17rem;
  }
}

.InfoSection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #111519;
  margin: 0 -2.5rem;
  padding: 1rem 2.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.InfoSection-CenterBlock {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  width: 100%;
}

.InfoSection-InfoHeader {
  font-family: 'AvenirNextLTProBold';
  font-size: 1.2rem;
  margin-bottom: 1.4rem;
  text-transform: uppercase;
}

.InfoSection-SideBlock {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.InfoSection-SideBlock--right {
  text-align: right;
}

.InfoSection-Content {
  color: #6c7580;
  line-height: 1.4rem;
  font-size: 1.2rem;
}

.InfoSection-Content--green {
  color: #00ae75;
  line-height: 2.8rem;
  position: relative;
  top: -1rem;
}

.InfoSection-Content--underline {
  text-decoration: underline;
}

.InfoSection-InfoTextBlock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 1rem 0;
}

.InfoSection-InfoText {
  font-size: 1.1rem;
  font-family: 'AvenirNextLTProBold';
  color: #6c7580;
  line-height: 1.4;
}

.InfoSection-SponsorsIcons {
  max-width: 65rem;
  margin: 0 -1.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 4rem;
}

.SponsorsIcons-Icon {
  font-size: 3.5rem;
  color: #ffffff;
  max-height: 4rem;
  height: 4rem;
  min-height: 4rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.SponsorsIcons-Icon--bigger {
  font-size: 6.5rem;
  position: relative;
  top: 0.2rem;
}
@media (min-width: 600px) {
  .InfoSection-SponsorsIcons {
    margin: 0 auto;
    height: 5rem;
  }

  .SponsorsIcons-Icon {
    font-size: 7rem;
    max-height: 5rem;
    height: 5rem;
    min-height: 5rem;
  }

  .SponsorsIcons-Icon--bigger {
    font-size: 11rem;
    top: 0.5rem;
  }
}
@media screen and (min-width: 640px) {
  .InfoSection {
    background: #060A0D;
    padding: 2.5rem;
  }

  .InfoSection-InfoTextBlock {
    margin: 3.5rem 0;
  }
}
@media (min-width: 1100px) {
  .InfoSection {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .InfoSection-CenterBlock {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
  }

  .InfoSection-InfoHeader {
    font-size: 1.4rem;
  }

  .InfoSection-SideBlock {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-top: 0;
  }

  .InfoSection-SideBlock--right {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .InfoSection-Content {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .InfoSection-Content--green {
    top: 0;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .InfoSection-SponsorsIcons {
    max-width: 55rem;
  }

  .InfoSection-InfoTextBlock {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .InfoSection-InfoText {
    font-size: 1.2rem;
    text-transform: uppercase;
    border-left: 2px solid #38414b;
    letter-spacing: 2px;
    white-space: nowrap;
    padding: 0 1.5rem;
  }

  .InfoSection-InfoText:first-child {
    border: none;
  }

  .SponsorsIcons-Icon {
    font-size: 7rem;
    color: #38414b;
  }

  .SponsorsIcons-Icon--bigger {
    font-size: 11rem;
  }

  .SponsorsIcons-Icon:hover {
    color: #ffffff;
  }
}
@media screen and (min-width: 1400px) {
  .InfoSection {
    padding: 5rem 3rem;
  }

  .InfoSection-InfoText {
    letter-spacing: 5px;
  }

  .InfoSection-SponsorsIcons {
    max-width: 70rem;
    margin-bottom: 3rem;
  }

  .SponsorsIcons-Icon {
    font-size: 8rem;
  }

  .SponsorsIcons-Icon--bigger {
    font-size: 12rem;
  }
}

.TabsComponent p {
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.TabsComponent p:last-child {
  margin-bottom: 3rem;
}

.TabsComponent-Tab {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.TabsComponent-Tab .ButtonOneBorder-labelBorder {
  height: 0.5rem;
}

.TabsComponent-Content {
  margin: 3.5rem auto 0;
  text-align: center;
  position: static;
  max-width: 47rem;
  padding: 0;
}

.TabsComponent-HeaderButton {
  margin-top: 4.5rem;
  text-align: center;
}

.TabsComponent-TextHeader {
  font-family: 'AvenirNextLTProBold';
  font-size: 1.8rem;
  display: none;
}
@media screen and (min-width: 1000px) {
  .TabsComponent-HeaderButton {
    margin-top: 0;
    text-align: left;
  }

  .TabsComponent-Content {
    opacity: 0;
    min-width: 100%;
    max-width: 43rem;
    padding: 7rem 0 4rem;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
  }

  .TabsComponent-Content--active {
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    position: absolute;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }

  .TabsComponent-Content--left {
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .TabsComponent-Content--right {
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);
  }

  .TabsComponent p {
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .TabsComponent-TextHeader {
    display: inline-block;
    margin-bottom: 1rem;
  }
}

.CuracaoTag {
  width: 4rem;
  height: 4rem;
}

.FooterTabs {
  margin: 0 auto;
  position: relative;
}

.FooterTabs-Icons {
  text-align: center;
  margin-top: 2.5rem;
  font-size: 4rem;
}

.FooterTabs-Icons span {
  margin: 0 1.2rem;
}

.FooterTabs-Icons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.FooterTabs-carousel {
  display: none;
}
@media screen and (min-width: 1000px) {
  .FooterTabs {
    position: relative;
    max-width: 130rem;
    margin-top: 5rem;
  }

  .FooterTabs--smaller {
    margin-top: 0;
  }

  .FooterTabs--smaller .FooterTabs-BackgroundImage {
    display: none;
  }

  .FooterTabs-BackgroundImage {
    display: block;
    min-height: 55rem;
    overflow: hidden;
  }

  .FooterTabs-Icons {
    text-align: center;
  }

  .FooterTabs-carousel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    overflow: hidden;
    max-width: 70rem;
    position: relative;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .FooterTabs-carousel-mobile {
    display: none;
  }

  .FooterTabs-carousel-headers {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 0.1rem solid #38414b;
  }

  .FooterTabs-carousel-headers .ButtonOneBorder-label {
    color: #6c7580;
    font-size: 1.6rem;
  }

  .FooterTabs-carousel-headers .ButtonOneBorder--active .ButtonOneBorder-label,
  .FooterTabs-carousel-headers .ButtonOneBorder:hover .ButtonOneBorder-label {
    color: #00ae75;
  }

  .FooterTabs-carousel-contents {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
  }
}

.SocialIcons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.SocialIcons-SocialIcon {
  display: inline-block;
  padding: 0.2rem 0 0 0.1rem;
  font-size: 1.4rem;
  line-height: 1;
}

.SocialIcons-SocialButton {
  color: #ffffff;
  width: 2.7rem;
  height: 2.7rem;
  line-height: 1;
  max-height: 2.7rem;
  min-height: 2.7rem;
  border-radius: 50%;
  border: 1px solid #ffffff;
  margin-left: 2.7rem;
  padding: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.SocialIcons-SocialButton:first-child {
  margin-left: 0;
}

.SocialIcons-SocialButton:hover {
  background-color: #ffffff;
  color: #000000;
}
@media (min-width: 640px) and (max-width: 1000px) {
  .SocialIcons-SocialIcon {
    font-size: 1.6rem;
  }

  .SocialIcons-SocialButton {
    width: 3.1rem;
    height: 3.1rem;
    max-height: 3.1rem;
    min-height: 3.1rem;
    margin-left: 2.5rem;
  }
}
@media (max-width: 639px) {
  .SocialIcons-SocialButton {
    margin-left: 3.7rem;
  }
}
.Select .Select-control {
  border: none;
  height: 3.2rem;
  width: 16.2rem;
  cursor: pointer;
  border-radius: 0;
  border-bottom: 0.3rem solid #ffffff;
}

.Select .Select-control .Select-placeholder,
.Select .Select-control .Select-value {
  padding: 0 1.4rem;
  line-height: 2rem;
  display: inline-block;
  vertical-align: middle;
  @media (min-width: 1700px) {
    padding: 0 1.4rem;
    line-height: 2.7rem;
  }
}
@media (max-width: 1000px) and (min-width: 639px) {
  .Select .Select-control .Select-placeholder,
  .Select .Select-control .Select-value {
    line-height: 3.2rem;
  }
}

.Select .Select-control .Select-arrow-zone {
  float: right;
  line-height: 2.6rem;
  padding-right: 1rem;
  vertical-align: middle;
  @media (min-width: 1700px) {
    line-height: 3.2rem;
  }
}

.Select .Select-control .Select-arrow-zone .Select-arrow {
  width: 0;
  height: 0;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  display: inline-block;
  border-top: 0.6rem solid #ffffff;
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
}

.Select .Select-menu-outer {
  background-color: #192127;
  position: absolute;
  width: 16.2rem;
  color: #ffffff;
  border: none;
  z-index: 10;
  display: none;
}

.Select.is-open .Select-menu-outer{
  display: block;
}

.Select .Select-menu-outer .Select-option {
  height: 3.2rem;
  cursor: pointer;
  padding: 0 1.4rem;
  line-height: 3.2rem;
  vertical-align: middle;

  background-color: #192127;
  color: #FFF;
  height: 3.2rem;
  cursor: pointer;
  padding: 0 1.4rem;
  line-height: 3.2rem;
  vertical-align: middle;
  border: 0;
  display: block;
  &:hover {
    background: $color-primary;
  }
}

.Select .Select-menu-outer .Select-option.is-selected {
  display: none;
}

.Select .Select-menu-outer .Select-option.is-focused {
  background-color: #00ae75;
}

.is-open.Select .Select-control {
  border-bottom: 0.3rem solid #00ae75;
}

.is-open.Select .Select-arrow-zone .Select-arrow {
  border-top: 0.6rem solid #00ae75;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.Select {
  position: relative;
  height: 4rem;
}

.Select .Select-control {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 0;
}

.Select .Select-control .Select-placeholder,
.Select .Select-control .Select-value {
  padding: 0;
  color: #ffffff;
  font-size: 1.8rem;
  padding-right: 1.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select .Select-control .Select-placeholder .Select-value-label,
.Select .Select-control .Select-value .Select-value-label {
  text-transform: capitalize;
}

.Select .Select-control .Select-arrow-zone {
  padding: 0;
}

.Select .Select-menu-outer {
  -webkit-transition: height 0.5s ease-in-out;
  -o-transition: height 0.5s ease-in-out;
  transition: height 0.5s ease-in-out;
  width: 100%;
}

.Select .Select-menu-outer .Select-option {
  text-transform: capitalize;
  padding: 1.4rem;
  line-height: 1em;
  font-size: 1.2rem;
  height: auto;
}

.Select .Select-menu-outer .Select-option.is-selected {
  display: none;
}

.Select {
  margin-bottom: 1.5rem;
}

.Select .Select-value,
.Select .Select-value-label {
  font-size: 2rem;
}

.Select .Select-control {
  width: 100%;
  border-bottom-width: 0.2rem;
  padding: 0;
}

.Select .Select-control .Select-input {
  display: block;
}

.Select .Select-control .Select-input input {
  pointer-events: none;
  position: absolute;
  background: none;
  outline: none;
  border: none;
  border: none;
  left: 0;
  top: 0;
}
.LocaleSelect {
  position: relative;
  max-height: 3.5rem;
}

.LocaleSelect-error {
  position: absolute;
  bottom: 4rem;
  width: 30rem;
  padding: 1rem 2rem;
  border-radius: 3rem;
  background-color: #d72a2a;
  -webkit-transform: translateX(-38%);
  -ms-transform: translateX(-38%);
  -o-transform: translateX(-38%);
  transform: translateX(-38%);
}

.LocaleSelect .SelectField .Select {
  width: 8.5rem;
    height: 3rem;
  border-radius: 2rem;
  border: 2px solid #38414b;
  padding: 0 1.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;

  @media (min-width: 1700px) {
    height: 3.5rem;
  }
}

.LocaleSelect .SelectField .Select .Select-value-label {
  font-family: 'AvenirNextLTProBold';
  font-size: 1.2rem;
  letter-spacing: 1px;
  @media (min-width: 1700px) {
    font-size: 1.4rem;
  }
}

.LocaleSelect .SelectField .Select .Select-arrow-zone .Select-arrow {
  border-top-color: #38414b;
  position: relative;
  top: -2px;
}

.LocaleSelect .SelectField .Select .Select-control {
  width: 5.5rem;
  padding: 0;
  border-bottom: none;
  display: block;
}

.LocaleSelect .SelectField .Select .Select-menu-outer {
  -webkit-transform: translateX(-1.5rem);
  -ms-transform: translateX(-1.5rem);
  -o-transform: translateX(-1.5rem);
  transform: translateX(-1.5rem);
}
@media (min-width: 639px) {
  .LocaleSelect .SelectField .Select .Select-menu-outer {
    -webkit-transform: translateX(-15%);
    -ms-transform: translateX(-15%);
    -o-transform: translateX(-15%);
    transform: translateX(-15%);
  }
}
@media (max-width: 1000px) {
  .LocaleSelect .SelectField .Select {
    width: 10rem;
    height: 4rem;
  }

  .LocaleSelect .SelectField .Select .Select-control {
    width: 7rem;
  }

  .LocaleSelect .SelectField .Select .Select-value-label {
    font-size: 1.6rem;
  }
}
@media (max-width: 1000px) {
  .LocaleSelect {
    max-height: 3rem;
  }

  .LocaleSelect .SelectField .Select {
    width: 10rem;
    height: 3rem;
  }

  .LocaleSelect .SelectField .Select .Select-control {
    width: 7rem;
  }

  .LocaleSelect .SelectField .Select .Select-control .Select-value {
    line-height: 2rem;
  }

  .LocaleSelect .SelectField .Select .Select-control .Select-arrow-zone {
    line-height: 2.8rem;
  }

  .LocaleSelect .SelectField .Select .Select-value-label {
    font-size: 1.2rem;
  }
}


.btn-expand-footer{
  display: inline-block;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 2px solid transparent;
  text-align: center;
  text-transform: uppercase;
  font-family: $font-family-avenir-next-lt-pro-bold;
  font-weight: normal;
  transition: 0.25s background,  0.25s transform;
  letter-spacing: 0.05rem;
  position: relative;
  transform: translate(-50%, 0);
  .icon{
    position: absolute;
    top: 0.5rem;
    left: 1.8rem;
    font-size: 1.8rem;
  }
  span{
    font-size: 1rem;
    display: block;
    padding-top: 2.4rem;
  }
}

.btn-expand-footer.btn-expand-footer-action--leave {
  transform: translate(-50%, 10rem) !important;
}

.PageFooter-Expand{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 30;
    background: #010101;
    transition: 0.5s transform;
    transition-delay: 0.25s;
    transform: translate(0, 120%);
}
.PageFooter-Expand--enter{
    transform: translate(0, 0);
}
.PageFooter-ExpandContent{
    max-height: calc(100vh - 15rem);
    overflow: hidden;
}

/* Expand button */

.ExpandButton {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    border: 2px solid #6c7580;
    position: absolute;
    top: 0;
    left: 50%;
    cursor: pointer;
    z-index: 100;
    transform: translate(-50%, -150%);
    transition: all .3s ease
}
.ExpandButton-content {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column
}
.ExpandButton-content--reverse {
    padding-top: 1.5rem
}
.ExpandButton-icon {
    font-size: 2.5rem;
    color: #fff
}
.ExpandButton-text {
    color: #fff;
    position: relative;
    top: -.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: AvenirNextLTProBold
}
.ExpandButton-text--reverse {
    top: .2rem
}
.ExpandButton--active {
    background-color: #4083c9;
    border: none;
    animation-name: downAndUp;
    animation-duration: 1s;
    transform: translate(-50%, -50%)
}
.ExpandButton-FadeInOut-leave {
    transition: all 1s ease;
    transform: translate(-50%, 100%)
}
.ExpandButton-FadeInOut-enter {
    animation-name: show;
    animation-duration: 1s
}
@keyframes downAndUp {
    0% {
        transform: translate(-50%, -150%);
        opacity: 1
    }
    15% {
        transform: translate(-50%, 20%);
        opacity: 0
    }
    35%,
    to {
        transform: translate(-50%, -50%);
        opacity: 1
    }
}
@keyframes show {
    0% {
        transform: translate(-50%)
    }
    to {
        transform: translate(-50%, -150%)
    }
}
