.pageLoader {
    &.hide-loader{
      .ContentLoader-background, .ContentLoader {
        opacity: 0;
      }
    }
}

.ContentLoader-background {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 102;
  opacity: 1;
  transition: all 1s;
  background-color: #000;
  background-size: cover;
  background-position: 50%;
  background-image: url('/img/loader-background.jpg');
  transition: .6s opacity;
}

.ContentLoader {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 102;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
  width: 100%;
  transition: .3s opacity;
}
.ContentLoader-text {
  text-transform: uppercase;
  color: $white;
  margin-top: 2rem;
  font-size: 1.2rem
}
.ContentLoader .BodyMovin {
  width: 13rem;
}
