.modal-responsible {
  @media screen and (min-width: 1200px) {
    left: 20vh;
  }
}


.Responsible-modal-article {
  display: flex;
  height: 100vh;

  .col-image {
    height: 100vh;
    position: relative;
    padding-right: 44vh;
    background-position: center center;
    background-size: cover;
    @media screen and (max-width: 1200px) {
      padding-right: 22vh;
    }


    @media screen and (max-width: 760px) {
      display: none;
    }
  }

  .col-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  h3 {
    @include fontSize(60);
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-weight: 800;
    margin: 0;
    text-align: left;
    line-height: 1;
    span {
      display: block;
      color: $color-primary;
    }

    @media screen and (max-width: 1700px) {
      font-size: 4rem;
    }
  }
  p {
    @include fontSize(22);
    font-family: $font-family-montserrat-light;
    font-weight: 400;
    line-height: 1.3;
    margin: 0 0 3.125rem;
    span {
      color: $color-primary;
    }
    @media screen and (max-width: 1700px) {
      font-size: 1.6rem;
    }
  }
  img {
    margin: 0 1rem 0 0;
  }

  .content-header {
    padding: 4rem 10rem 4rem 10rem;
    @media screen and (max-width: 1700px) {
      padding: 4rem 6.25rem 4rem 6.25rem;
    }
    @media screen and (max-width: 1200px) {
      padding: 4rem 3rem 4rem 3rem;
    }
    @media screen and (max-width: 740px) {
      padding: 2rem 3rem 1rem 3rem;
    }
  }

  .content-body {
    padding: 0 10rem 4rem 10rem;
    @media screen and (max-width: 1700px) {
      padding: 0 6.25rem 4rem 6.25rem;
    }
    @media screen and (max-width: 1200px) {
      padding: 4rem 3rem 4rem 3rem;
    }
    @media screen and (max-width: 740px) {
      padding: 1rem 3rem 1rem 3rem;
    }
  }
}
