


.scroll-info {
    background: url(/img/shadow_blue.png) center center no-repeat;
    background-size: 157px 157px;
    width: 157px;
    height: 157px;
    color: #4083c9;
    font-size: 1rem;
    padding-top: 3.8rem;
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-weight: 800;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    -webkit-animation: 1.6s ease-in infinite wheel-up-down;
    -moz-animation: 1.6s ease-in infinite wheel-up-down;
    animation: 1.6s ease-in infinite wheel-up-down;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 60px;
      margin: 0 auto;
      background: url(/img/icon-arrow-d.png) center center no-repeat;
      width: 19px;
      height: 26px;
  }
}

@-webkit-keyframes wheel-up-down {
	0% {
	    margin-top: 2px;
	    opacity: 0;
	}
	30% {
	    opacity: 1;
	}
	100% {
	    margin-top: 20px;
	    opacity: 0;
	}
}

@-moz-keyframes wheel-up-down {
	0% {
	    margin-top: 2px;
	    opacity: 0;
	}
	30% {
	    opacity: 1;
	}
	100% {
	    margin-top: 20px;
	    opacity: 0;
	}
}

@keyframes wheel-up-down {
	0% {
	    margin-top: 2px;
	    opacity: 0;
	}
	30% {
	    opacity: 1;
	}
	100% {
	    margin-top: 20px;
	    opacity: 0;
	}
}
