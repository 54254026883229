.PageHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 90;
  background: rgba($black, 0);
  transition: .2s background;

  &.showBkg {
    background: rgba($black, 1);

    &.PageHeaderBkgOpacity {
      background: rgba($black, .8);
    }

    + .PageHeader-SubMenu {
      background: rgba($black, .8);
    }
  }
  @media #{$max-phone-menu} {
    background: rgba($black, 1);
  }
  @media #{$phone-menu} {
    &.PageHeader-StaticBackground {
      background: rgba($black, 0.8);
    }
  }
}

.PageHeader-SubMenu {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 90;
  transition: .2s background;
  background: rgba($black, 0);

  &::before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 1rem;
    right: 2rem;
    height: .1rem;
    background: rgba(#FFF, 0.2);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
      a {
        font-size: 1.2rem;
        letter-spacing: .05em;
        font-family: $font-family-avenir-next-lt-pro-bold;
        font-weight: normal;
        color: #687283;
        white-space: nowrap;
        text-transform: uppercase;
        height: 3.8rem;
        line-height: 3.8rem;
        display: inline-block;
        position: relative;
        text-decoration: none;
        margin-right: 2.5rem;

        &::before {
          display: block;
          content: "";
          height: .3rem;
          width: 0;
          background: $color-primary;
          position: absolute;
          left: 0;
          top: 0;
        }

        &:hover {
          color: $color-primary;
          transition: .5s color;

          &::before {
            width: 100%;
            transition: .5s width;
          }
        }
      }

      &:first-child {
        padding-left: 1rem;
      }

      &:last-child {
        padding-right: 2.5rem;
      }

      &.active {
        a {
          color: #FFF;

          &::before {
            background: #FFF;
            transition: .5s width;
            width: 100%;
          }
        }
      }
    }
  }
  @media #{$phone-menu} {
    top: 4.2rem;
  }
  @media #{$max-phone-menu} {
    background: rgba(#000000, .8);
    bottom: 0;

    &::before {
      left: 0;
      right: 0;
    }

    ul {
      align-items: center;
      flex-wrap: nowrap;
      overflow: auto;

      li {
        a {
          height: 3.4rem;
          line-height: 3.4rem;
        }
      }
    }
  }
  @media #{$width-1700} {
    &::before {
      left: 2rem;
    }

    ul li {
      &:first-child {
        padding-left: 2rem;
      }
    }
  }
}

.PageHeader-topBar {
  display: flex;
  height: 4.2rem;
  justify-content: space-between;
  transition: cubic-bezier(.5, 0, .3, 1.5) .5s;
  width: 100%;
  z-index: 102;
  @media #{$max-phone-menu} {
    justify-content: flex-end;
  }

  &.PageHeader-black {
    background: $menuBackground;
  }

  .PageHeader-fixie {
    flex: 1 1;
  }
}

.PageHeader-SignLogo {
  display: block;
  height: 4.2rem;
  margin: 0 1rem;
  max-width: 4rem;
  position: relative;
  width: 4rem;
  z-index: 4;

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }

  path {
    fill: $color-primary;
  }

  &.PageHeader-SignLogo-white {
    path {
      fill: #FFF;
    }
  }
  @media #{$max-phone-menu} {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
  }
  @media #{$width-1700} {
    margin: 0 2rem;
  }

  &.PageHeader-SignLogo-Center {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
  }
}

.PageHeader-BitcoinLogo {
  height: 4rem;
  width: 7rem;

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }

  path {
    fill: #FFF;
  }
  @media #{$max-phone-menu} {
    display: none;
  }
}

.PageHeader-SignLogo-Hidden {
  display: none;
  height: 4.2rem;
  max-width: 4rem;

  svg {
    height: 100%;
    width: 100%;
  }

  path {
    fill: $color-primary;
  }
}

.PageHeader-UserBar {
  align-items: center;
  display: flex;
  height: 4.2rem;
  justify-content: right;
  padding: .2rem 8.4rem .2rem 2rem;
  text-align: right;
  @media #{$max-phone-menu} {
    padding-right: 1rem;
  }

  .PageHeader-topBar-LeftToggle & {
    padding-right: 2em;
  }
}

.PageHeader-Button {
  font-size: 1.2rem;
  line-height: 1;
  min-height: auto;
  padding: .8rem 2.3rem .8rem 1.8rem;

  i::before {
    display: inline-block;
    line-height: .5;
    position: relative;
    top: .2rem;
  }
  @media #{$max-phone-menu} {
    font-size: 1.4rem;
    padding: .5rem 1.4rem .5rem 1.1rem;
  }
  @media #{$width-1700} {
    font-size: 1.4rem;
    padding: .8rem 1.8rem .8rem 1.3rem;
  }
}

.PageHeader-topBar-navigation {
  align-items: center;
  background: #000000;
  display: flex;
  height: 4.2rem;
  justify-content: space-between;
  left: 0;
  padding: 0 8.3rem 0 2rem;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: .6s all cubic-bezier(0.27, 1, 0.71, 1);
  z-index: 1;

  .PageHeader.open-navbar & {
    transform: translateX(0);
  }
  @media #{$max-phone-menu} {
    display: none;
  }
}

.HeaderNavigation {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
}

.HeaderButton {
  text-transform: uppercase;
  position: relative;
  height: 100%;
}

.HeaderButton-button {
  padding: 0;
  margin: 0;
}

.HeaderButton-label,
.HeaderButtonHorizontal-label {
  font-family: 'AvenirNextLTProBold';
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.2rem;
  white-space: nowrap;
  padding: 0 1.3rem;
  cursor: pointer;
  color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.HeaderButton-labelIcon,
.HeaderButtonHorizontal-labelIcon {
  -webkit-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
  padding-bottom: 1rem;
  line-height: 1.6rem;
  font-size: 2.5rem;
}

.HeaderButton-labelBorder {
  -webkit-transition: width .5s ease-out;
  -o-transition: width .5s ease-out;
  transition: width .5s ease-out;
  background: #00ae75;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-top: -0.3rem;
  height: .3rem;
  width: 0;
}

.HeaderButton-option {
  font-family: 'AvenirNextLTProDemi';
  padding: 1.6rem 3.2rem;
  text-transform: none;
  white-space: nowrap;
  font-size: 1.6rem;
  color: #ffffff;
}

.HeaderButton-option:hover {
  background: #038056;
}

.HeaderButton-options {
  min-width: 100%;
  max-height: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: absolute;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #00ae75;
  -webkit-transition: max-height .5s ease-out;
  -o-transition: max-height .5s ease-out;
  transition: max-height .5s ease-out;
}

.HeaderButton--active .HeaderButton-options,
.HeaderButton:hover .HeaderButton-options {
  max-height: 50rem;
  -webkit-transition: max-height .5s ease-in;
  -o-transition: max-height .5s ease-in;
  transition: max-height .5s ease-in;
}

.HeaderButton--active .HeaderButton-label,
.HeaderButton--active .HeaderButtonHorizontal-label,
.HeaderButton:hover .HeaderButton-label,
.HeaderButton:hover .HeaderButtonHorizontal-label {
  color: #00ae75;
}

.HeaderButton--active .HeaderButton-labelBorder,
.HeaderButton:hover .HeaderButton-labelBorder {
  -webkit-transition: width .5s ease-in;
  -o-transition: width .5s ease-in;
  transition: width .5s ease-in;
  width: 100%;
}

.HeaderButtonHorizontal-label {
  padding: 0 .9rem !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.HeaderButtonHorizontal-labelIcon {
  padding-right: 1rem !important;
  padding-bottom: 0 !important;
  @media screen and (max-width: 1350px) and (min-width: 1240px) {
    .HeaderButtonHorizontal-labelIcon {
      padding-right: .5rem !important;
    }
  }
  @media #{$max-tablet-menu} {
    padding-right: 0 !important;
    margin-right: .5rem;
  }
}
@media screen and (min-width: 1700px) {
  .HeaderButtonHorizontal-label {
    padding: 0 1.2rem !important;
  }

  .HeaderButton-label,
  .HeaderButtonHorizontal-label {
    font-size: 1.6rem;
    padding: 0 2.1rem;
  }

  .HeaderButton-labelIcon,
  .HeaderButtonHorizontal-labelIcon {
    font-size: 3rem;
  }
}
@media screen and (max-width: 1350px) and (min-width: 1240px) {
  .HeaderButton-labelIcon,
  .HeaderButtonHorizontal-labelIcon,
  .HeaderButtonHorizontal-labelIcon {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1240px) {
  .HeaderButtonHorizontal-label .HeaderButton-text {
    display: none;
  }
}

.PageHeader-topBar-mobileMenu {
  transition: .5s cubic-bezier(0.68, -0.55, 0.24, 1) all;
  transform: translateX(-100vw);
  position: fixed;
  top: 4.2rem;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.94);
  color: #ffffff;
  z-index: 200;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: calc(100vh - 4.2rem);
  overflow: auto;
  text-align: center;

  .PageHeader.open-navbar & {
    transform: translateX(0);
  }
  @media #{$phone-menu} {
    display: none;
  }
}

.MobileHeaderNavigation {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative;
}

.MobileHeaderNavigation--visible {}

.MobileHeaderNavigation-hamburgerIcon {
  padding: 0;
  font-size: 3rem;
  color: #ffffff;
  margin-top: .7rem;
}

.MobileHeaderNavigation-topRow {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: absolute;
  background: #000;
  left: 0;
  right: 0;
  top: 0;
  height: 4.3rem;
  z-index: 10;
  padding-left: 1.5rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0;
  -ms-flex: 0 0;
  flex: 0 0;
  width: 100vw;
}

.MobileHeaderNavigation-body {
  position: relative;
  overflow: auto;
  padding-top: 5.5rem;
  max-height: 100vh;
}

.MobileHeaderNavigation-closeIcon {
  font-size: 2rem;
  color: #ffffff;
  margin-top: .7rem;
  padding: 0;
}

.MobileHeaderNavigation-mobileMenu {
  -webkit-transition: .5s cubic-bezier(0.68, -0.55, 0.24, 1) all;
  -o-transition: .5s cubic-bezier(0.68, -0.55, 0.24, 1) all;
  transition: .5s cubic-bezier(0.68, -0.55, 0.24, 1) all;
  -webkit-transform: translateX(-100vw);
  -ms-transform: translateX(-100vw);
  -o-transform: translateX(-100vw);
  transform: translateX(-100vw);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.94);
  color: #ffffff;
  z-index: 200;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 100vh;
}

.MobileHeaderNavigation-mobileMenu--visible {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.MobileHeaderNavigation-mobileMenu:before {
  content: '';
  display: block;
  top: 0;
  bottom: 0;
  left: -20rem;
  right: 0;
  position: absolute;
  width: 20rem;
  background: rgba(0, 0, 0, 0.94);
}
@media screen and (min-width: 760px) {
  .MobileHeaderNavigation-mobileMenu {
    max-width: 32rem;
  }
}

.NavigationButtons {
  padding: 1rem 1.5rem 2rem;
}

.NavigationButtons-ButtonRow {
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateX(-150%);
  -ms-transform: translateX(-150%);
  -o-transform: translateX(-150%);
  transform: translateX(-150%);
  opacity: 1;
  -webkit-transition: .5s cubic-bezier(0.57, -0.34, 0.25, 1) all;
  -o-transition: .5s cubic-bezier(0.57, -0.34, 0.25, 1) all;
  transition: .5s cubic-bezier(0.57, -0.34, 0.25, 1) all;
  visibility: visible;
}

.NavigationButtons-ButtonRow--left {
  visibility: hidden;
  -webkit-transform: translateX(120%);
  -ms-transform: translateX(120%);
  -o-transform: translateX(120%);
  transform: translateX(120%);
}

.NavigationButtons-ButtonRow--right {
  visibility: hidden;
  -webkit-transform: translateX(-120%);
  -ms-transform: translateX(-120%);
  -o-transform: translateX(-120%);
  transform: translateX(-120%);
}

.NavigationButtons-ButtonRow:last-child {
  margin-bottom: 0;
}

.PageHeader.open-navbar .NavigationButtons-ButtonRow {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.NavigationButtons-Link {
  font-size: 2.6rem;
  padding: 0;
  line-height: 1;
  min-height: 0;
  border: 0;
  letter-spacing: 2px;
  color: #FFF;
  margin: 0 auto;
}

.NavigationButtons-Link--active {
  color: #00ae75;
}

.NavigationButtons-Link:hover {
  color: #00ae75;
}

.NavigationButtons-DotsIcon {
  font-size: 4rem;
  color: #38414b;
}

.NavigationButtons-DotsIcon--active {
  color: #ffffff;
}

.PageHeader-toggle {
  display: block;
  height: 4.2rem;
  width: 4.2rem;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 2;
  padding: 1.1rem .9rem;
  //background: $black;
  transition: .25s ease-in-out background;

  &.PageHeader-toggle-getting {
    left: 1rem !important;
    right: auto !important;
    z-index: 100 !important;
    position: fixed;
  }

  body.no-touch-devices & {
    right: 1.1rem;
  }
  @media #{$max-tablet-menu} {}
  @media #{$max-phone-menu} {
    right: auto;
    left: 0;
  }
}

.PageHeader-toggle-box {
  position: relative;
  display: block;
  height: 3rem;
  width: 3rem;
}

.PageHeader-toggle span {
  display: block;
  position: absolute;
  height: 3px;
  width: 25px;
  background: $white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  cursor: pointer;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  will-change: transform;
}

.PageHeader-toggle span:nth-child(1) {
  top: 0;
}

.PageHeader-toggle span:nth-child(2) {
  top: 8px;
  width: 19px;
}

.PageHeader-toggle span:nth-child(3) {
  top: 16px;
}
@media #{$phone-menu} {
  .PageHeader-toggle.PageHeader-toggle-Colors:hover span {
    background: $color-secondary;
  }

  .PageHeader-toggle.open.PageHeader-toggle-Colors {
    background: $color-secondary;
  }

  .PageHeader-toggle.open.PageHeader-toggle-Colors span {
    background: $white;
  }

  .PageHeader-toggle.open.PageHeader-toggle-Colors:hover {
    background: $color-primary;
  }
}

.PageHeader-toggle.open span:nth-child(1) {
  top: 8px;
  transform: rotate(135deg);
}

.PageHeader-toggle.open span:nth-child(2) {
  opacity: 0;
  right: -60px;
}

.PageHeader-toggle.open span:nth-child(3) {
  top: 8px;
  transform: rotate(-135deg);
}

.PageHeader-VipMenu {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    height: 4.2rem;
    align-items: center;

    li {
      padding: 0 1.5rem;

      a {
        font-family: 'PlayfairDisplayBlack';
        font-size: 1.2rem;
        font-weight: normal;
        text-transform: uppercase;
        color: #FFF;
        transition: .2s color;
        letter-spacing: .2rem;

        &:hover {
          color: $gold;
        }
      }

      &.active {
        a {
          color: $gold;
        }
      }
    }
  }
  @media #{$max-phone-menu} {
    display: none;
  }
}

.PageHeader-VipMenu-Mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#000000, .8);
  z-index: 11;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: .1rem;
    background: #353537;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    align-items: center;

    li {
      a {
        height: 3.4rem;
        line-height: 3.4rem;
        margin-left: 2.5rem;
        letter-spacing: .05em;
        font-family: 'AvenirNextLTProBold';
        white-space: nowrap;
        text-transform: uppercase;
        color: #FFFFFF;
        display: block;
        position: relative;
        transition: .2s color;

        &::before {
          display: block;
          content: "";
          height: .3rem;
          width: 0;
          background: $gold;
          position: absolute;
          left: 0;
          top: 0;
          transition: .5s width;
        }

        &:hover {
          color: $gold;

          &::before {
            width: 100%;
          }
        }
      }

      &:last-child {
        padding-right: 2.5rem;
      }

      &.active {
        a {
          color: $gold;

          &::before {
            width: 100%;
          }
        }
      }
    }
  }
  @media #{$phone-menu} {
    display: none;
  }
}
