
.page-getting-started {
  color: #FFF;

  .Getting-nav {
    position: fixed;
    z-index: 92;
    left: 5rem;
    top: 2rem;
    @media all and (max-width: 1440px) {
      left: 2rem;
      top: 2rem;
    }
    @media all and (max-width: 1400px) {
      top: 1rem;
    }
  }
  .Getting-nav-dots {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: 1.8rem;
    @media all and (max-width: 1400px){
      top: 1.2rem;
    }
    ul {
      display: inline-block;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: inline-block;
        margin: 0 1.2rem;
        width: 1rem;
        height: 1rem;
        background: #fff;
        cursor: pointer;
        border-radius: 50%;
        vertical-align: middle;
        transition: all .4s cubic-bezier(.165,.84,.44,1) 0s;
        &:last-child{

          @media all and (max-width: 1023px){
            display: none;
          }
        }
        a {
         text-indent:-9999px;
         color: transparent;
         width: 18px;
         height: 18px;
         display: block;
        }
        &.active,
        &:hover  {
          background: transparent;
          border: 0.2rem solid #4083c9;
          position: relative;
          width: 2.1rem;
          height: 2.1rem;
          margin: -0.2rem 0.65rem 0;
          transition: all .4s cubic-bezier(.165,.84,.44,1) 0s;
        }
      }
    }
    .icon {
      display: inline-block;
      float: right;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
  .Getting-footer-copyright {
    position: absolute;
    z-index: 10;
    bottom: 2rem;
    left: 2rem;
    font-family: $font-family-montserrat-light;
    font-weight: 400;
    font-size:1.2rem;
    @media all and (max-width: 1400px){
      bottom: 1.4rem;
    }
    @media all and (max-width: 480px) {
      display: none;
    }
    span {
        color: #fff;
        font-family: $font-family-montserrat-bold;
        font-weight: 800;
    }
  }
  .Getting-login-action {
    position: absolute;
    z-index: 10;
    right: 4.8rem;
    top: 1.2rem;
    @media all and (max-width: 1440px) {
      right: 2rem;
    }
    @media all and (max-width: 1366px) {
      top: 0.7rem;
    }
    a {
      font-size: 1.4rem;
      line-height: 3.2rem;
      padding: 0 1.3rem;
      height: 3.5rem;
      &:first-child {
        margin-right: 1.5rem;
        @media all and (max-width: 640px) {
          margin-right: 0.2rem;
        }
      }
      @media all and (max-width: 1023px) {
        font-size: 1.2rem;
        line-height: 3rem;
        height: 3rem;
      }
      @media all and (max-width: 1366px) {
        line-height:2.2rem;
        height: 2.5rem;
        font-size: 1.2rem;
      }
    }
  }

  .Getting-wrapper {
    position: relative;
    left:0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1
  }
  .GettingLanding-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // background-size: 100% auto !important;
    -webkit-background-size: 100% !important;
    background-size: 100% !important;
    height: 100vh;
    z-index: 2;
    -webkit-transition: 1s ease-in all;
    transform: 1s ease-in all;
    // -webkit-perspective: 1000;
    // will-change: transform;
    // @media screen and (min-height: 1000px) {
    //   background-size: 130% !important;
    // }
    @media screen and (min-height: 1100px) {
      background-size: 125% !important;
    }
    @media screen and (min-width: 1800px) and (min-height: 1080px) {
      background-size: 105% !important;
    }
    @media screen and (max-width: 1480px) {
      background-size: 110% !important;
    }
    @media screen and (max-width: 1366px) {
      background-size: 100% !important;
    }
    @media screen and (max-width: 1280px) {
      background-size: 118% !important;
    }
    @media screen and (max-width: 1170px) {
      background-size: 142% !important;
    }
    @media screen and (max-width: 1280px) and (min-height: 890px){
      background-size: 130% !important;
    }
    @media screen and (width: 1366px) and (height: 1024px) {
      background-size: 135% !important;
    }
    @media screen and (width: 1024px) and (height: 1366px) {
      background-size: 230% !important;
    }
    @media all and (min-width: 1024px) {
      background: url('/files/getting-started/KV-7360px_v2.jpg') no-repeat center center;
    }
  }
  .Getting-landing {
    height: 100vh;
    z-index: 4;
    left: 0;
     top: 0;
     right: 0;
     bottom: 0;

     @media #{$max-phone-menu} {
       margin-top: 4.2rem;
     }
    article {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      @include clearfix();
      .GettingLanding-data {
        position: absolute;
        top: 28vh;
        left: 14.8rem;
        bottom: 12rem;
        z-index: 99;
        width: 27rem;
        strong {
          color: #00ae73;
        }
        @media all and (max-width: 1440px) {
          left: 5rem;
        }
        h2 {
          font-size: 3.5rem;
          line-height: 3.9rem;
          font-family: $font-family-avenir-next-lt-pro-bold;
          font-weight: 800;
          margin: 0 0 5rem;
          text-transform: capitalize;
          @media screen and (max-width: 1366px) {
            font-size: 2.5rem;
            line-height: 2.5rem;
          }
          @media screen and (max-width: 640px) {
            @include fontSize(32);
            margin: 0 auto;
            width: 80%;
            text-align: center;
            line-height: 3.4rem;
           }
        }
        p {
          @include fontSize(24);
          font-family: $font-family-montserrat-light;
          font-weight: 400;
          line-height: 3.2rem;
          padding: 0 0 5.2rem;
          max-width: 62rem;
          margin: 0;
          width: 23.4rem;
          @media screen and (max-width: 1366px) {
            font-size: 2rem;
            line-height: 2.5rem;
          }
          @media all and (max-width: 1440px) {
            padding-bottom: 1rem;
          }
          @media screen and (max-width: 640px) {
            @include fontSize(18);
            margin: 2rem auto 0;
            width:90%;
            text-align: center;
            line-height: 2.2rem;
            padding: 0 0 3rem;
            strong {
              display: block;
            }
           }
        }
      }
    }
  }
  .Getting-message-bg {
    position: absolute;
    top: 0;
    width: 26%;
    z-index: 1;
    bottom: 0;
    height: 100%;
    height: 100vh;
    @media all and (max-width: 1680px) {
        width: 30%;
    }
    @media all and (max-width: 1366px) {
        width: 24%;
    }
    &.Getting-msg-left {
      left: 0;
    }
    &.Getting-msg-right {
      right: 0;
    }
    &.Getting-msg-center {
      left: 0;
      right: 0;
      width: 100%;
    }
  }
  .getting-msg-fixed-1,
  .getting-msg-fixed-2,
  .getting-msg-fixed-3,
  .getting-msg-fix-4  {
    position: fixed !important;
      background: rgba(0,0,0, 0.75);
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    will-change: scroll-position;
  }
  .Getting-message-landing {
    position: absolute;
    top: 0;
    width: 27rem;
  }
  @media all and (max-width: 1023px) {
    .Getting-message-landing .scroll-info{
      display: none;
    }
  }
  .Getting-message {
    position: absolute;
    top: 0;
    width: 26%;
    z-index: 1;
    padding: 0;
    @media all and (max-width: 1680px) {
      width: 30%;
    }
    @media all and (max-width: 1366px) {
        width: 24%;
    }
    > div {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      right: 0;
      margin: 0 auto;
      text-align: left;
      width: 65%;
    }
    h3 {
      font-family: $font-family-avenir-next-lt-pro-bold;
      font-weight: 800;
      font-size: 3.4rem;
      line-height: 4.2rem;
      margin: 0 0 6rem 0;
      @media all and (max-width: 1480px) {
          font-size: 3rem;
      }
      @media screen and (max-width: 1366px) {
        font-size: 2.5rem;
        line-height: 3rem;
        margin-bottom: 3rem;
      }
    }
    p {
      font-family: $font-family-montserrat-light;
      font-weight: 400;
      line-height: 2.3rem;
      font-size: 1.8rem;
      @include clearfix();
      margin-bottom: 3rem;
      @media screen and (max-width: 1366px) {
        font-size: 1.6rem;
        line-height: 2.5rem;
      }
    }
    a {
      font-family: $font-family-montserrat-bold;
      color: #4083c9;
      font-weight: 800;
      text-decoration: none;
    }
    small {
        font-family: $font-family-montserrat-regular;
        font-size: 1.4rem;
        font-weight: 400;
        margin-top: 8rem;
        width: 70%;
        @media screen and (max-width: 1366px) {
          font-size: 1.2rem;
        }
    }
    &.getting-msg-4 {
      > div {
        text-align: center;
        width: 100%;
      }
    }
  }
  .getting-msg-1 {
    left: 0;
    top: 0;
    bottom: 0;
    span {
      color: #4083c9;
      display: block;
    }
  }
  .getting-msg-2 {
    span {
      color: #00ae75;
      display: inline-block;
    }
  }
  .getting-msg-3 {
    span {
      color: #4083c9;
      display: inline-block;
    }
  }
  .getting-msg-2,
  .getting-msg-3 {
    top: 0;
    bottom: 0;
    right: 0;
    article {
      float: right;
    }
  }
  .getting-msg-4 {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    margin: 0 auto;
    width: 35%;
    p {
      width: 100%;
    }
    @media all and (max-width: 1366px) {
        width: 50%;
    }
    @media all and (max-width: 1440px) {
        width: 40%;
    }
    article {
      width: 30%;
      text-align: center;
      margin: 0 auto;
    }
    .getting-msg-head {
      font-family: $font-family-avenir-next-lt-pro-bold;
      font-weight: 800;
      font-size: 3.2rem;
      line-height: 4.2rem;
      text-transform: capitalize;
      margin: 0 0 4.8rem 0;
      @media screen and (max-width: 1366px) {
        font-size: 2.6rem;
        line-height: 3.2rem;
      }
      @media screen and (max-width: 640px) {
        font-size: 2.2rem;
        line-height: 2.4rem;
      }
    }
    span {
      color: #00ae75;
      display: block
    }
    p {
      font-family: $font-family-montserrat-light;
      font-weight: 400;
      line-height: 2.6rem;
      font-size: 1.6rem;
      text-align: center;
      margin: 0 0 4rem 0;
      @media screen and (max-width: 1366px) {
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 3rem;
      }
    }
    a {
        color: #00ae75;
        &.getting-msg-link {
          font-family: $font-family-montserrat-bold;
          font-weight: 800;
          font-size: 1.8rem;
          @media screen and (max-width: 1366px) {
            font-size: 1.6rem;
          }
        }
    }
  }


  .Getting-banners {
    display:table;
    width: 100%;
    overflow: hidden;
    z-index: 91 !important;
    position: absolute;
    background: #000;
    @media all and (max-width: 1023px) {
      display: block;
      white-space: nowrap;
    }

    @media all and (min-width: 1024px) {
      &:hover {
        article {
          width:9.5%
        }
      }
    }
    .getting-menu-scroll {
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
      @media all and (max-width: 1023px) {
        height: 100vh;
      }
    }
    article {
      display:table-cell;
      width: 14.28%;
      height: 100vh;
      position: relative;
      cursor: pointer;
      z-index: 90;
      background-size: cover;
      transition: width .6s ease-in-out;
      @media all and (max-width: 1023px) {
        display: block;
        width: 20rem;
        flex: 0 0 20rem;
        height: auto;
        overflow: hidden;
      }
      @media all and (max-width: 768px) {
        //width: 45%;
      }
      a {
        text-decoration: none;
        color: #fff;
        cursor: pointer;
        display: block;
        height: 100%;
      }
      &:hover {
        z-index: 99;
      }
      .bannerBg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: opacity .2s ease-in-out;

      }
      .Getting-banners-data-base {
        font-size: 1.4rem;
        font-family: $font-family-avenir-next-lt-pro-bold;
        font-weight: 800;
        text-transform: uppercase;
        text-align: right;
        position: absolute;
        top: 5.4rem;
        right: 3.6rem;
        transition: opacity .2s ease-in-out;
      }
      .Getting-banners-data {
        position: absolute;
        top: 0;
        width: 40rem;
        opacity: 0;
        left: 5.5rem;
        @media all and (max-width: 1440px) {
          left: 2rem;
        }
        @media all and (max-width: 1440px) {
          right: 2rem;
          top: 2rem;
        }
        h3 {
          font-family: $font-family-avenir-next-lt-pro-bold;
          font-weight: 800;
          text-transform: uppercase;
          font-size: 3.6rem;
          text-align: left;
          position: relative;
          top: -2rem;
          transition: all .8s ease-in-out .8s;
          @media all and (max-width: 1440px) {
            font-size: 2.6rem;
          }
        }
        p {
          width:50%;
          font-family: $font-family-montserrat-light;
          font-weight: 400;
          font-size: 2rem;
          display: block;
          width: 60%;
          @media all and (max-width: 1440px) {
            font-size: 1.6rem;
            width: 50%;
          }
        }
        .Getting-banners-action {
          margin: 4.2rem 0 0;

          .Button {
            font-size: 1.6rem;
            line-height: 1.6rem;
            padding: 2rem 2.5rem;

            @media all and (max-width: 1700px) {
              padding: 1rem 2rem;
              font-size: 1.2rem;
            }
          }
        }
      }
      &.hide {
        .Getting-banners-data-base {
          opacity: 0;
        }
        .Getting-banners-data {
          z-index: -2;
          visibility: hidden;
          transition: none;
        }
        .bannerBg {
          opacity: 0.25;
        }
      }
      @media all and (min-width: 1024px) {
      &.getting-active-box {
        width: 43%;

          .Getting-banners-data-base {
            opacity: 0;
            transition: none;
          }

        .Getting-banners-data {
          top: 12.2rem;
          opacity: 1;
          transition: all .4s ease-in-out .6s;

          h3{
            top: 0;
          }

          @media all and (max-width: 1440px) {
            top: 8rem;
          }
        }
        .Getting-banners-action {
          display: block;
          @media all and (max-width: 1440px) {
            margin: 2rem 0 0;
          }
        }
       }
      }
    }
  }

  .logo-bitwin {
    position: absolute;
    top:1.5rem;
    left: 0;
    right: 0;
    z-index: 10;
    margin: 0 auto;
    text-align: center;
    @media all and (max-width: 1366px) {
      top: 0.8rem;
    }
    @media all and (max-width: 640px) {
      left: 7.5rem;
      right: auto;
      top: 0.8rem;
    }
      svg {
        width: 5.8rem;
        fill: #00ae75;
        @media all and (max-width: 1366px) {
          width: 4rem;
        }
    }
  }



  // .parallux.not-full {
  //   overflow: hidden;
  //   .parallux-bg {
  //     height: 100%;
  //     bottom: initial;
  //   }
  // }

  // .parallux.no-parallax {
  //   overflow: hidden;
  //   .parallux-bg {
  //     position: relative;
  //   }
  //   .parallux-bg, .parallux-inner {
  //     background-attachment: initial;
  //   }
  // }

  .parallux {
    position: relative;
    height: 100vh;
    z-index: 4;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    &.bg-fixed {
      .parallux-inner {
        background-attachment: fixed;
      }
    }
    .parallux-bg {
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      -webkit-perspective: 1000;
      position: fixed;
      background-size: cover;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      -webkit-perspective: 1000;
      will-change: transform;
      // transform: translate3d(0,0,0);
      .parallux-inner {
        top: 0px;
        left: 0px;
        position: relative;
        min-width: 100%;
        height: 100%;
        height: 100%;
      }
    }
  }
  .getting-top-wrapper,
  .getting-bottom-wrapper {
    background: rgba(0,0,0, 0.8);
    position: fixed;
    z-index: 91;
    left:0;
    right: 0;
  }

  .getting-bottom-wrapper {
    bottom: 0;
    height: 5.5rem;
    @media all and (max-width: 1400px){
      height: 4rem;
    }
  }


   .Getting-banners {
     transform: translateX(-100%);
     position: fixed;
     width: 100vw;
     height: 100vh !important;
     will-change: transform;
     backface-visibility: hidden;
     @media all and (min-width: 1024px) {
       transition: 1s ease-in-out transform;
     }
   }
   .getting-nav-open  {
     overflow-y:hidden;
     .Getting-banners {
       transform: translateX(0);
     }
   }

   .gettingLandingAnimate {
      background-size: 100% !important;
      -webkit-transition: 1s ease-in-out all;
      transition: 1s ease-in-out all;
      -webkit-backface-visibility: hidden;
   }
   .GettingLanding-bg.gettingLanding-animateToLeft {
     -webkit-transition: 1s ease-in all;
     transition: 1s ease-in all;
     -webkit-backface-visibility: hidden;
      transform-origin: 100% bottom !important;
      -ms-transform-origin: 100% bottom !important;
      background-size: 200% !important;
      background-position: 0 100%;
      @media all and (max-width: 1680px) {
          background-size: 220% !important;
      }
      @media all and (max-width: 1440px) {
          background-size: 200% !important;
      }
      @media all and (max-width: 1280px) {
          background-size: 240% !important;
      }
   }
   .GettingLanding-bg.gettingLanding-animateToRight {
     -webkit-transition: 1s ease-in-out all;
     transition: 1s ease-in-out all;
      -webkit-backface-visibility: hidden;
      //  transform-origin: 0 100%;
       background-size: 200% !important;
       background-position: right 70%;
       @media all and (max-width: 1680px) {
           background-size: 220% !important;
       }
       @media all and (max-width: 1440px) {
           background-size: 250% !important;
       }
   }
   .GettingLanding-bg.gettingLanding-animateToTop {
     -webkit-transition: 1s ease-in-out all;
     transition: 1s ease-in-out all;
     -webkit-backface-visibility: hidden;
      background-size: 160% !important;
      background-position: 80% 20%;
      @media all and (max-width: 1680px) {
          background-size: 160% !important;
      }
      @media all and (max-width: 1440px) {
          background-size: 180% !important;
          background-position: 78% 18%;
      }
      @media all and (max-width: 1280px) {
          background-size: 180% !important;
          background-position: 80% 18%;
      }
   }

   .GettingLanding-bg.gettingLanding-animateToCenter {
     transition: .1s ease-in-out all !important;
     -webkit-backface-visibility: hidden;
      transform-origin: center center !important;
      background-size: 100% !important;
      background-position: 50% 50%;
      @media screen and (width: 1366px) and (height: 1024px) {
    //    background-size: 135% !important;
      }
      @media screen and (width: 1024px) and (height: 1366px) {
      //  background-size: 230% !important;
      }
   }


  .getting-md-bg,
  .Getting-desc-md {
    display: none;
  }
  .Getting-banners-wp {
   height: 10px;
  }

  .parallux-md-hide {
    z-index: 0;
  }

  // Tablet media query
   @media all and (max-width: 1023px) {
  .Getting-banners-wp {
    height: 100px;
  }
  .Getting-banners {
    article {
      .Getting-banners-data {
        top: 2rem;
        background: rgba(0,0,0,0.6);
        bottom: 0;
        top: auto;
        height: 10rem;
        left: 0;
        right: 0;
        width: 100%;
        h3 {
          position: absolute;
          font-size: 3.8rem;
          left: 2.2rem;
          bottom: 3rem;
          text-transform: none;
          @media all and (max-width: 640px) {
              left: 1rem;
              font-size: 2.4rem;
          }
        }
        }
      }
    }
     .parallux {
       height: auto;
     }
    .getting-md-bg {
      max-width: 100%;
      height: auto;
      display: block;
    }
    .parallux-bg,
     .parallux-md-hide {
       display: none;
     }
     .getting-msg-1 {
       span {
         display: inline-block;
       }
     }
     .bs.more {
       margin: 0 auto;
     }
     .Getting-landing {
       article {
          width: 100%;
          text-align: center;
        .GettingLanding-data {
          width: 80%;
          left: 0;
          top: auto;
          margin: 0 auto;
          right: 0;
          top: 70%;
          bottom: auto;

          @media all and (max-width: 640px) {
            bottom: 2rem;
            top: auto;
          }

          h2 {
            font-size: 4.5rem;
            line-height: 4.5rem;
            margin: 0 0 2rem 0;
            width: 100%;
            @media all and (max-width: 640px) {
              font-size: 3rem;
              line-height: 3.2rem;
              margin-bottom: 1rem;
            }
          }
          p {
           font-size: 2.5rem;
           line-height: 3rem;
           width: 100%;
           max-width: 100%;
           @media all and (max-width: 640px) {
            font-size: 2rem;
            line-height: 2.2rem;
            font-family: $font-family-avenir-next-lt-pro-bold;
            }
          }
       }
      }
     }
     .Getting-desc-md {
       text-align: center;
       padding: 4rem 0;
       display: none;
       p {
         width: 80%;
        font-size: 2.4rem;
        line-height: 2.4rem;
        margin: 0 auto;
       }
     }
     .getting-welcome-bg {
       background-size: cover;
       background: url('/files/getting-started/Getting-started-1280-Assets_01.jpg');
     }
     .getting-register-bg {
       background: url('/files/getting-started/Getting-started-1280-Assets_02.jpg');
     }
     .getting-deposit-bg {
       background: url('/files/getting-started/Getting-started-1280-Assets_04.jpg');
     }
     .getting-enjoy-bg {
       background: url('/files/getting-started/Getting-started-1280-Assets_06.jpg');
     }
     .getting-questions-bg {
       background: url('/files/getting-started/Getting-started-1280-Assets_08.jpg');
     }
     .Getting-message-bg  {
       display: none;
     }
     .Getting-message {
       width: 100%;
       text-align: center;
       @include clearfix();
       position: relative;
      //  display: none;
       > div {
         position: relative;
         transform: none;
        -webkit-transform: none;
        -ms-transform: none;
        top: none;
        bottom: 0;
        width: 80%;
        text-align: center;
        margin-top: -24rem;

        @media all and (max-width: 640px) {
          margin-top: -10rem;
          width: 90%;
        }
       }
       h3 {
         font-size: 4.5rem;
         text-align: center;
         margin: 0 0 2rem;
         @media all and (max-width: 640px) {
           font-size: 3.6rem;
           line-height: 4rem;
           margin-bottom: 1rem;
         }
      }
      p {
        font-size: 2rem;
        line-height: 3rem;
        text-align: center;
        @media all and (max-width: 640px) {
         font-size: 1.6rem;
         line-height: 2.2rem;
         }
      }
      small {
        font-size: 1.7rem;
      }
      &.getting-msg-4 {
        position: static;
        > div {
          width: 80%;
          margin-top: -16rem;
          @media all and (min-width: 640px) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin-top: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
     }
   }

  @media all and (max-width: 1023px) {
    .GettingprogressContainer {
      left: 0;
      width: 100%;
      height: 0.4em;
      margin-bottom: 0px;
      position: fixed;
      bottom: 2rem;
      overflow: hidden;
      background-color: rgba(255,255,255, 0.2);
      content: "";
    	display: table;
    	table-layout: fixed;
      z-index: 999;
      display: none;
    }
    .GettingprogressBar {
      width: 0%;
      float: left;
      height: 100%;
      z-index:99;
      max-width: 100%;
      background-color: #4083C9;
      -webkit-transition: width .3s ease;
      -o-transition: width .3s ease;
      transition: width .3s ease;
    }
    .getting-nav-open {
      .GettingprogressContainer {
        display: block;
      }
    }
  }
}
