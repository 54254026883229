.page-bitcoin {
  .parallux.not-full {
    .parallux-bg {
      height: 100%;
    }
  }

  .parallux.no-parallax {
    overflow: hidden;

    .parallux-bg {
      position: relative;
    }
  }

  .parallux {
    height: auto;
    position: relative;

    .parallux-bg {
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateZ(0);
      will-change: transform;
    }

    .parallux-inner {
      //background-attachment: fixed;
      background-position: 50% 50%;
      background-size: cover;
      height: auto;
      min-height: 100%;
      min-width: 100%;
      position: relative;
      transform: translateZ(0);
      //will-change: transform;
      background-repeat: no-repeat;
      @media all and (max-width: 1023px) {
        background-attachment: scroll;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100%;

        &::after {
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .01) 30%, rgba(0, 0, 0, 1) 100%);
          content: "";
          height: 100%;
          position: absolute;
          width: 100%;
        }
      }
      @media all and (max-width: 767px) {
        &::after {
          content: normal;
        }
      }

      img {
        display: none;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .parallax-bg-1 {
      background-image: url("/files/bitcoin/parallax_1.jpg");
      @media all and (max-width: 1023px) {
        background-image: url("/files/bitcoin/parallax_1_1280.jpg");
      }
      @media all and (max-width: 767px) {
        background-image: url("/files/bitcoin/640-assets_01.jpg");
      }
    }

    .parallax-bg-2 {
      background-image: url("/files/bitcoin/parallax_2.jpg");
      @media all and (max-width: 1023px) {
        background-image: url("/files/bitcoin/parallax_2_1280.jpg");
      }
      @media all and (max-width: 767px) {
        background-image: url("/files/bitcoin/640-assets_02.jpg");
      }
    }

    .parallax-bg-3 {
      background-image: url("/files/bitcoin/parallax_3.jpg");
      @media all and (max-width: 1023px) {
        background-image: url("/files/bitcoin/parallax_3_1280.jpg");
      }
      @media all and (max-width: 767px) {
        background-image: url("/files/bitcoin/640-assets_05.jpg");
      }
    }

    .parallax-bg-4 {
      background-image: url("/files/bitcoin/parallax_4.jpg");
      @media all and (max-width: 1023px) {
        background-image: url("/files/bitcoin/parallax_4_1280.jpg");
      }
      @media all and (max-width: 767px) {
        background-image: url("/files/bitcoin/640-assets_07.jpg");
      }
    }

    .parallax-bg-5 {
      background-image: url("/files/bitcoin/parallax_5.jpg");
      @media all and (max-width: 1023px) {
        background-image: url("/files/bitcoin/parallax_5_1280.jpg");
      }
      @media all and (max-width: 767px) {
        background-image: url("/files/bitcoin/640-assets_09.jpg");
      }
    }

    .parallax-bg-6 {
      background-image: url("/files/bitcoin/parallax_6.jpg");
      @media all and (max-width: 1023px) {
        background-image: url("/files/bitcoin/parallax_6_1280.jpg");
      }
      @media all and (max-width: 767px) {
        background-image: url("/files/bitcoin/640-assets_11.jpg");
      }
    }

    .parallax-bg-7 {
      background-image: url("/files/bitcoin/parallax_7.jpg");
      @media all and (max-width: 1023px) {
        background-image: url("/files/bitcoin/parallax_7_1280.jpg");
      }
      @media all and (max-width: 767px) {
        background-image: url("/files/bitcoin/640-assets_14.jpg");
      }
    }

    .parallax-bg-8 {
      background-image: url("/files/bitcoin/parallax_8.jpg");
      @media all and (max-width: 1023px) {
        background-image: url("/files/bitcoin/parallax_8_1280.jpg");
      }
      @media all and (max-width: 767px) {
        background-image: url("/files/bitcoin/640-assets_16.jpg");
      }
    }

    .parallax-bg-9 {
      background-image: url("/files/bitcoin/parallax_9.jpg");
      @media all and (max-width: 1023px) {
        background-image: url("/files/bitcoin/parallax_9_1280.jpg");
      }
      @media all and (max-width: 767px) {
        background-image: url("/files/bitcoin/640-assets_18.jpg");
      }
    }
  }

  article {
    &.a-blue {
      .data-label {
        color: $color-secondary;
      }

      p {
        a {
          color: $color-secondary;
        }
      }
    }

    &.a-green {
      .data-label {
        color: $color-primary;
      }

      p {
        a {
          color: $color-primary;
        }
      }
    }
  }

  .animated-scroll {
    .animated-scroll-title {
      opacity: 0;
      transform: translate(-15vw, 0);
      transition: transform .7s cubic-bezier(0.165, 0.84, 0.44, 1), opacity .7s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .animated-scroll-lead {
      opacity: 0;
      transform: translate(-15vw, 0);
      transition: transform .7s cubic-bezier(0.165, 0.84, 0.44, 1) .2s, opacity .7s cubic-bezier(0.165, 0.84, 0.44, 1) .2s;
    }

    .animated-scroll-text {
      opacity: 0;
      transform: translate(-15vw, 0);
      transition: transform .7s cubic-bezier(.165, .84, .44, 1) .2s, opacity .7s cubic-bezier(.165, .84, .44, 1) .2s;
    }

    .animated-scroll-lead {
      + .animated-scroll-text {
        transition: transform .7s cubic-bezier(0.165, 0.84, 0.44, 1) .4s, opacity .7s cubic-bezier(0.165, 0.84, 0.44, 1) .4s;
      }
    }

    &.showElement {
      .animated-scroll-title {
        opacity: 1;
        transform: translate(0, 0);
      }

      .animated-scroll-lead {
        opacity: 1;
        transform: translate(0, 0);
      }

      .animated-scroll-text {
        opacity: 1;
        transform: translate(0, 0);
        @media screen and (max-width: 1023px) {
          left: 14rem;
          position: absolute;
        }
      }
    }
  }

  .data {
    align-items: center;
    color: $white;
    display: flex;
    min-height: 100vh;
    overflow: hidden;
    padding: 5rem 0;
    position: relative;

    .data-cell {
      flex: 1 1;
      padding: 0 0 0 5em;
    }

    .data-label {
      display: inline-block;
      font-family: $font-family-avenir-next-lt-pro-bold;
      font-size: 2.4rem;
      line-height: 1.1;
      margin: 0 0 3rem;
      position: relative;
      text-transform: uppercase;

      &::after {
        background: #374245;
        bottom: -4px;
        content: "";
        height: 1px;
        left: -5.875em;
        position: absolute;
        right: 0;
      }
      @media screen and (max-width: 1024px) {
        &::after {
          left: 0;
        }
      }
      @media screen and (max-width: 767px) {
        &::after {
          background: none;
        }
      }
    }

    .data-lead {
      font-family: $font-family-avenir-next-lt-pro-bold;
      font-size: 5.185rem;
      font-weight: normal;
      line-height: 1.05;
      margin: 0;
      padding: 1rem 0 2rem;
      position: relative;
    }

    .h3 {
      font-family: $font-family-avenir-next-lt-pro-bold;
      font-size: 3rem;
      font-weight: normal;
      margin: 0 0 1.2rem;
      @media all and (max-width: 768px) {
        padding: 0 3rem;
      }
    }

    .h4 {
      font-family: $font-family-avenir-next-lt-pro-bold;
      font-size: 2.6rem;
      font-weight: normal;
      margin: 0 0 1.2rem;
    }

    p {
      font-family: $font-family-montserrat-light;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.4;
      padding: 0 0 3rem;
      position: relative;

      a:not(.btn) {
        text-decoration: underline;
      }
    }

    .text-small {
      font-size: 1.6rem;
    }
    @media all and (max-width: 1700px) {
      .data-cell {
        padding: 0 0 0 6rem;
      }

      .data-label {
        font-size: 2rem;
        margin: 0 0 2rem;
      }

      .data-lead {
        font-size: 3.3rem;
        padding: 1rem 0 1.6rem;
      }

      .h3 {
        font-size: 2.4rem;
        margin: 0 0 1rem;
      }

      .h4 {
        font-size: 2rem;
        margin: 0 0 1rem;
      }

      p {
        font-size: 1.6rem;
        padding: 0 0 2rem;
      }
    }
    @media all and (max-width: 1700px) {
      .data-cell {
        padding: 0 0 0 4rem;
      }
    }
    @media all and (max-width: 1260px) {
      .data-cell {
        padding: 0 0 0 2rem;
      }

      p {
        font-size: 1.4rem;
        line-height: 1.5;
        padding: 0 0 2rem;
      }

      .text-small {
        font-size: 1.4rem;
      }
    }
    @media all and (max-width: 1023px) {
      .data-cell {
        padding: 0;
      }
    }
    @media all and (max-width: 768px) {
      .data-lead {
        padding: 1rem 3rem 1.6rem;
      }

      p {
        font-size: 1.7rem;
        line-height: 1.5;
        padding: 0 3rem 2rem;
      }
    }
  }

  .d1 {
    .data {
      max-width: 49rem;
    }
  }

  .d3 {
    .data {
      max-width: 66rem;
    }

    .main-text {
      margin: 3rem 0;
    }
  }

  .d4 {
    .data {
      max-width: 68rem;
    }

    .main-text {
      margin: 3rem 0 2rem;
    }
  }

  .d5 {
    .data {
      max-width: 69rem;
    }
  }

  .d6 {
    .data {
      max-width: 90rem;
    }

    .green-list-info {
      margin: 0 0 5rem;
      max-width: 54.5rem;
    }

    .first-text {
      max-width: 60rem;
    }
  }

  .d9 {
    .data {
      max-width: 50rem;
    }
  }
  @media all and (max-width: 1700px) {
    .d1 {
      .data {
        max-width: 34rem;
      }
    }

    .d3 {
      .data {
        max-width: 50rem;
      }

      .main-text {
        margin-top: 0;
      }
    }

    .d4 {
      .data {
        max-width: 56rem;

        p {
          max-width: 47rem;
        }
      }

      .main-text {
        margin: 0 0 2rem;
      }
    }

    .d5 {
      .data {
        max-width: 50rem;
      }
    }

    .d6 {
      .data {
        max-width: 56rem;
      }

      .green-list-info {
        margin: 0 0 2rem;
        max-width: 44rem;
      }

      .first-text {
        max-width: 52rem;
      }
    }

    .d9 {
      .data {
        max-width: 40rem;
      }
    }
  }
  @media all and (max-width: 1260px) {
    .d3 {
      .data {
        max-width: 38rem;
      }

      .main-text {
        margin-top: 0;
      }
    }

    .d4 {
      .data {
        max-width: 42rem;

        p {
          max-width: 38rem;
        }
      }
    }

    .d5 {
      .data {
        max-width: 42rem;
      }
    }

    .d6 {
      .data {
        max-width: 38rem;
      }
    }

    .d9 {
      .data {
        max-width: 36rem;
      }
    }
  }
  @media all and (max-width: 1023px) {
    .d1 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 93vh;
        text-align: center;

        .data-cell {
          bottom: 14rem;
          position: relative;
        }
      }
    }

    .d2 {
      .data {
        align-items: flex-end;
        min-height: 100vh;
        text-align: center;

        .data-label {
          margin: 53rem 0 2rem;
        }
      }

      .animated-scroll.showElement .animated-scroll-text {
        left: 0;
        position: relative;
      }

      .banner-1,
      .banner-2 {
        margin: 0 auto;
      }

      .banner-1 {
        margin: 2rem auto 4rem;
      }
    }

    .d3 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 100vh;
        text-align: center;

        .data-cell {
          position: relative;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          position: relative;
        }
      }
    }

    .d4 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 100vh;
        text-align: center;

        p {
          max-width: 47rem;
        }

        .data-label {
          margin-top: 66vw;
        }

        .data-cell {
          position: relative;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          position: relative;
        }
      }
    }

    .d5 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 100vh;
        text-align: center;

        .data-cell {
          position: relative;
        }

        .data-label {
          margin: 53rem 0 2rem;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          position: relative;
        }
      }
    }

    .d6 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 100vh;
        text-align: center;

        .data-cell {
          position: relative;
        }

        .data-label {
          margin: 53rem 0 2rem;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          position: relative;
        }
      }
    }

    .d7 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 100vh;
        text-align: center;

        .data-cell {
          position: relative;
        }

        .data-label {
          margin: 53rem 0 2rem;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          margin: 0 auto;
          position: relative;
          width: 60%;
        }
      }
    }

    .d8 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 100vh;
        text-align: center;

        .data-cell {
          position: relative;
        }

        .data-label {
          margin: 53rem 0 2rem;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          margin: 0 auto;
          position: relative;
          width: 60%;
        }
      }
    }

    .d9 {
      .data {
        align-items: flex-end;
        margin: 0 auto 10rem;
        min-height: 100vh;
        text-align: center;

        .data-cell {
          margin-top: 34rem;
          padding: 0;
          position: relative;
        }

        .data-label {
          margin: 20rem 0 2rem;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          position: relative;
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .data {
      .data-label {
        margin: 0;
      }
    }

    .d1 {
      .data {
        height: 74vh;
        padding: 5rem 0 0;

        .data-cell {
          bottom: 0;
        }

        .data-lead {
          padding: .5rem 3rem 1.6rem;
        }
      }

      .animated-scroll.showElement .animated-scroll-text {
        display: none;
      }
    }

    .d2 {
      .data {
        align-items: flex-end;
        min-height: 100vh;
        padding: 5rem 0 0;
        text-align: center;

        .data-label {
          margin: 90vw 0 2rem;
        }
      }

      .animated-scroll.showElement .animated-scroll-text {
        left: 0;
        position: relative;
      }

      .banner-1,
      .banner-2 {
        margin: 0 auto;
      }
    }

    .d3 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 73vh;
        padding-bottom: 1rem;
        text-align: center;

        .data-lead {
          br {
            display: none;
          }
        }

        .data-cell {
          position: relative;
        }

        .data-label {
          margin: 90vw 0 2rem;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          position: relative;
        }
      }
    }

    .d4 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 83vh;
        padding-bottom: 1rem;
        text-align: center;

        .logos-rec + p {
          margin-top: -10rem;
        }

        .data-cell {
          position: relative;
        }

        .data-label {
          margin: 90vw 0 2rem;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          position: relative;
        }
      }
    }

    .d5 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 86vh;
        padding-bottom: 1rem;
        text-align: center;

        .data-cell {
          position: relative;
        }

        .data-label {
          margin: 90vw 0 2rem;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          position: relative;
        }
      }
    }

    .d6 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 95vh;
        padding-bottom: 1rem;
        text-align: center;

        .h3 {
          padding: 0 7rem;
        }

        .data-cell {
          position: relative;
        }

        .data-label {
          margin: 83vw 0 2rem;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          position: relative;
        }
      }
    }

    .d7 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 85vh;
        padding-bottom: 1rem;
        text-align: center;

        .data-cell {
          position: relative;
        }

        .data-label {
          margin: 90vw 0 0;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          margin: 0 auto;
          position: relative;
          width: 100%;
        }
      }
    }

    .d8 {
      .data {
        align-items: flex-end;
        margin: 0 auto;
        min-height: 96vh;
        padding-bottom: 1rem;
        text-align: center;

        .data-cell {
          position: relative;
        }

        .data-label {
          margin: 90vw 0 0;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          margin: 0 auto;
          position: relative;
          width: 100%;
        }
      }
    }

    .d9 {
      .data {
        align-items: flex-end;
        margin: 0 auto 10rem;
        min-height: 100vh;
        text-align: center;

        .data-cell {
          margin-top: 97vw;
          padding: 0;
          position: relative;
        }

        .data-label {
          margin: 0;
        }

        .animated-scroll.showElement .animated-scroll-text {
          left: 0;
          position: relative;
        }
      }
    }
  }

  .logos-rec {
    margin: -2rem 0 -5rem;
    padding: 0;

    > li {
      display: inline-block;
      text-align: middle;
      vertical-align: middle;

      + li {
        padding-left: 5.5rem;
      }
    }

    a {
      text-decoration: none;
    }

    .icon {
      color: #434d57;
      font-size: 14rem;
      transition: .25s color;

      &:hover {
        color: $white;
      }
    }
  }
  @media all and (max-width: 1700px) {
    .logos-rec {
      margin: -2rem 0 -3rem;

      > li {
        + li {
          padding-left: 4rem;
        }
      }

      .icon {
        font-size: 10rem;
      }
    }
  }
  @media all and (max-width: 768px) {
    .logos-rec {
      margin: 0;

      > li {
        display: block;
        margin-top: -3rem;

        + li {
          margin-top: -10rem;
          padding-left: 0;
        }

        &:last-child {
          margin-top: -12rem;
        }
      }

      .icon {
        color: $white;
        font-size: 20rem;

        &.icon-coinbase {
          font-size: 15rem;
        }

        &.icon-circle {
          font-size: 18rem;
        }
      }
    }
  }

  .green-list-info {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: #00ae75;
      font-family: $font-family-montserrat-bold;
      font-size: 1.6rem;
      min-height: 6rem;
      padding: 2rem 9.5rem;
      position: relative;

      &:nth-child(1) {
        background-position: left center;
        padding-right: 0;

        &::before {
          background: url("/files/numbers/1G.png") no-repeat center center;
          background-size: cover;
          content: "";
          height: 7.5rem;
          left: 0;
          position: absolute;
          top: 0;
          width: 7.5rem;
        }
      }

      &:nth-child(2) {
        background-position: right center;
        text-align: right;

        &::before {
          background: url("/files/numbers/2G.png") no-repeat center center;
          background-size: cover;
          content: "";
          height: 7.5rem;
          position: absolute;
          right: 0;
          top: 0;
          width: 7.5rem;
        }
      }

      &:nth-child(3) {
        background-position: right center;
        text-align: right;

        &::before {
          background: url("/files/numbers/3G.png") no-repeat center center;
          background-size: cover;
          content: "";
          height: 7.5rem;
          position: absolute;
          right: 0;
          top: 0;
          width: 7.5rem;
        }
      }
    }
  }
  @media all and (max-width: 1700px) {
    .green-list-info {
      li {
        font-size: 1.4rem;
        padding: 0 6rem 2rem;

        &:nth-child(1) {
          &::before {
            height: 5rem;
            width: 5rem;
          }
        }

        &:nth-child(2) {
          &::before {
            height: 5rem;
            width: 5rem;
          }
        }

        &:nth-child(3) {
          &::before {
            height: 5rem;
            width: 5rem;
          }
        }
      }
    }
  }
  @media all and (max-width: 1260px) {
    .green-list-info {
      li {
        &:nth-child(2) {
          padding-left: 0;
        }
      }
    }
  }
  @media all and (max-width: 768px) {
    .green-list-info {
      li {
        font-size: 1.6rem;
        margin-bottom: 2rem;
        padding: 8rem 5rem 0;
        text-align: center;

        &:nth-child(1) {
          padding-right: 5rem;
          text-align: center;

          &::before {
            height: 8rem;
            margin: 0 auto;
            right: 0;
            width: 8rem;
          }
        }

        &:nth-child(2) {
          padding-left: 5rem;
          text-align: center;

          &::before {
            height: 8rem;
            left: 0;
            margin: 0 auto;
            width: 8rem;
          }
        }

        &:nth-child(3) {
          padding-left: 5rem;
          text-align: center;

          &::before {
            height: 8rem;
            left: 0;
            margin: 0 auto;
            width: 8rem;
          }
        }
      }
    }
  }

  .fun-facts-list {
    list-style: none;
    margin: 3rem 0 0;
    padding: 0;

    > li {
      + li {
        margin-top: 4.5rem;
      }
    }

    h3 {
      border-left: .5rem solid $color-secondary;
      font-family: $font-family-avenir-next-lt-pro-bold;
      font-size: 2.2rem;
      font-weight: 600;
      line-height: 1.1;
      margin: 0 0 2.2rem;
      padding-left: 1.5rem;
      position: relative;
      text-transform: uppercase;
    }

    p {
      padding-left: 2rem;

      strong {
        color: $color-secondary;
        font-family: $font-family-montserrat-bold;
        font-weight: 800;
      }
    }
    @media all and (max-width: 1700px) {
      > li {
        + li {
          margin-top: 2rem;
        }

        h3 {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .btn {
    border: 2px solid transparent;
    border-radius: 4.5rem;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-size: 1.2rem;
    letter-spacing: .04em;
    line-height: 1;
    min-height: auto;
    padding: 1.6rem 5rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .25s cubic-bezier(.165, .84, .44, 1) 0s;

    &.btn-primary {
      background: transparent;
      border-color: #00ae75;
      color: $white;

      &:hover {
        background-color: rgba(0, 174, 117, .5);
      }

      &:focus {
        background-color: #00ae75;
      }

      &:active {
        background-color: #00ae75;
      }
    }

    &.btn-secondary {
      border-color: #4083c9;
      color: $white;
      background: transparent;

      &:hover {
        border-color: #4083c9;
        background-color: rgba(64, 131, 201, .5);
      }

      &:focus {
        border-color: #4083c9;
        background-color: #4083c9;
      }

      &:active {
        border-color: #4083c9;
        background-color: #4083c9;
      }
    }
  }
  @media all and (max-width: 1700px) {
    .btn {
      padding: 1.2rem 4rem;
    }
  }
  @media all and (max-width: 1260px) {
    .btn {
      padding: 1rem 3rem;
      font-size: 1rem;
    }
  }
  @media all and (max-width: 768px) {
    .btn {
      font-size: 1.7rem;
      left: 0;
      padding: 1.55rem 0;
      text-align: center;
      width: 268px;
    }
  }

  .btn-learn-more {
    position: absolute;
    left: -40px;
    text-align: center;
    cursor: pointer;
    background-color: red;
    will-change: transform;
    animation: btnLearnMore 3s infinite ease;
    animation-delay: 2s;

    > span {
      background: url("/files/bitcoin/shadow_blue.png") no-repeat center center;
      background-size: 157px 157px;
      width: 157px;
      height: 157px;
      color: $color-secondary;
      position: absolute;
      font-size: 1rem;
      font-family: $font-family-avenir-next-lt-pro-bold;
      text-transform: uppercase;
      padding-top: 4rem;
      letter-spacing: .1rem;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 66px;
        margin: 0 auto;
        background: url("/files/bitcoin/icon-arrow-d.png") no-repeat center center;
        width: 19px;
        height: 26px;
      }

      &:active,
      &:focus,
      &:hover {
        transform: scale(1.05);
        transition: all .25s cubic-bezier(.165, .84, .44, 1) 0s;
        color: $white;
      }
    }
  }
  @keyframes btnLearnMore {
    0%,
    20% {
      opacity: 1;
      transform: translate(-50%, 0);
    }

    40% {
      opacity: 0;
      transform: translate(-50%, 2rem);
    }

    60% {
      opacity: 0;
      transform: translate(-50%, -2rem);
    }

    100%,
    80% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
  /*
    Tabs
   */
  .data-tab {
    .tab-container {
      margin-top: 5.5rem;
    }

    .nav-tabs-bitcoin {
      float: left;
      list-style: none;
      padding: 0;
      position: relative;
      width: 14rem;

      &::before {
        background: #3d4448;
        content: "";
        display: block;
        height: 200vh;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
      }
    }
    @media all and (max-width: 767px) {
      .nav-tabs-bitcoin {
        display: none;
      }
    }

    .nav-tabs-bitcoin-mobile {
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;

      li {
        position: relative;

        &.nav-tab-left {
          float: left;
        }

        &.nav-tab-right {
          float: right;
        }
      }

      &.default-type {
        li {
          border: 2px solid #4083c9;
          font-size: 1.6rem;

          &.nav-tab-left {
            border-bottom-right-radius: 50%;
            border-left: 0;
            border-top-right-radius: 50%;
            padding: 9px 5px 8px 25px;

            i {
              position: relative;
              right: 7px;
            }
          }

          &.nav-tab-right {
            border-bottom-left-radius: 50%;
            border-right: 0;
            border-top-left-radius: 50%;
            padding: 9px 25px 8px 5px;

            i {
              left: 7px;
              position: relative;
            }
          }
        }
      }

      &.primary-type {
        @extend .default-type;

        li {
          border-color: #00ae75;
        }
      }
    }
    @media all and (min-width: 768px) {
      .nav-tabs-bitcoin-mobile {
        display: none;
      }
    }

    .tab-content {
      float: left;
      padding-left: 4rem;
      position: relative;
      width: 55rem;
    }

    .tab-pane {
      display: none;

      &.active {
        display: block;
      }
    }
  }
  @media all and (max-width: 1700px) {
    .data-tab {
      .tab-container {
        margin-top: 4rem;
      }

      .tab-content {
        margin-top: -.2rem;
        width: 40rem;
      }
    }
  }
  @media all and (max-width: 1260px) {
    .data-tab {
      .tab-container {
        margin-top: 3rem;
      }

      .nav-tabs-bitcoin {
        position: relative;
        width: 12rem;
      }

      .tab-content {
        width: 32rem;
        padding-left: 2rem;
        margin-top: -.8rem;
      }
    }
  }
  @media all and (max-width: 767px) {
    .data-tab {
      .tab-container {
        margin-top: 2rem;
      }

      .tab-content {
        float: none;
        margin: 0 auto;
        min-height: auto !important;
        padding-left: 0;
      }
    }
  }

  .nav-tabs-bitcoin > li {
    + li {
      margin-top: 4.6rem;
    }

    > a {
      display: block;
      background: none;
      border: 0;
      color: $white;
      text-align: right;
      cursor: pointer;
      font-family: $font-family-avenir-next-lt-pro-bold;
      letter-spacing: .025rem;
      text-decoration: none;
      line-height: 1.5em;
      text-transform: uppercase;
      font-size: 1.2rem;
      margin-right: 4.2rem;
      transition: .25s color;
      padding: 0;
      position: relative;

      &:hover {
        background: none;
        color: $color-secondary;
      }
    }

    &.active > a {
      color: $color-secondary;

      &:after {
        position: absolute;
        top: -1rem;
        right: -6rem;
        bottom: 0;
        width: 3.7rem;
        height: 3.7rem;
        background: #356ca6;
        color: $white;
        border-radius: 100%;
        content: 'z';
        font-family: "icons";
        text-transform: none;
        text-align: center;
        line-height: 3.7rem;
        animation-name: tabNanAnimation;
        animation-duration: .5s;
        animation-fill-mode: forwards;
      }
    }
  }

  .nav-tabs-bitcoin-green > li {
    > a {
      &:hover {
        color: $color-primary;
      }
    }

    &.active > a {
      color: $color-primary;

      &:after {
        background: #00ae75;
      }
    }
  }
  @media all and (max-width: 1700px) {
    .nav-tabs-bitcoin > li {
      + li {
        margin-top: 3.7rem;
      }
    }
  }
  @media all and (max-width: 1260px) {
    .nav-tabs-bitcoin > li {
      + li {
        margin-top: 3rem;
      }

      > a {
        font-size: 1rem;
        letter-spacing: .05rem;
        margin-right: 2rem;
      }

      &.active > a {
        &:after {
          top: -0.5rem;
          right: -3rem;
          width: 2.2rem;
          height: 2.2rem;
          line-height: 2.2rem;
        }
      }
    }
  }

  .tab-pane {
    h3 {
      opacity: 0;
      transform: translate(30px, 0);
    }

    .tabcontent-text {
      opacity: 0;
      transform: translate(30px, 0);
    }

    &.active {
      h3 {
        animation-name: tabPaneAnimation;
        animation-duration: .7s;
        animation-fill-mode: forwards;
      }

      .tabcontent-text {
        animation-name: tabPaneAnimation;
        animation-duration: .7s;
        animation-delay: .3s;
        animation-fill-mode: forwards;
      }
    }
  }
  @keyframes tabNanAnimation {
    0% {
      opacity: 0;
      transform: translate(-10px, 0);
    }

    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  @keyframes tabPaneAnimation {
    0% {
      opacity: 0;
      transform: translate(30px, 0);
    }

    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  .tab-article-title {
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-size: 5.185rem;
    line-height: 1.05;
    margin: 0;
    padding: 0 0 3.5rem;
    position: relative;
  }
  @media all and (max-width: 1700px) {
    .tab-article-title {
      font-size: 3.3rem;
      padding: 1rem 0 1.6rem;
    }
  }
  @media all and (max-width: 767px) {
    .tab-article-title {
      font-size: 2rem;
    }
  }

  .banner-1 {
    background: url("/files/bitcoin/man-bg.png") no-repeat center left;
    background-size: contain;
    height: 31rem;
    margin: -10rem 0 0 17rem;
    position: relative;
    width: 55.4rem;
    @media all and (max-width: 767px) {
      background: url("/files/bitcoin/man-mobile.jpg") no-repeat center top;
      background-size: 100%;
    }

    h3 {
      bottom: 42%;
      position: absolute;
    }

    .btn {
      bottom: 12%;
      left: -5rem;
      padding-left: 6rem;
      position: absolute;
      text-align: right;

      .icon {
        font-size: 2.8rem;
        left: 8px;
        position: absolute;
        top: 8px;
      }
    }
  }
  @media all and (max-width: 1700px) {
    .banner-1 {
      height: 24rem;
      margin: -8rem 0 0 5rem;
      width: 44rem;

      .btn {
        left: -4rem;
        padding-left: 5rem;
        padding-right: 5rem;

        .icon {
          font-size: 2rem;
        }
      }
    }
  }
  @media all and (max-width: 1260px) {
    .banner-1 {
      margin: -8rem 0 0 4rem;

      .btn {
        left: -3rem;
        padding-left: 4rem;
        padding-right: 4rem;

        .icon {
          font-size: 1.8rem;
          left: 6px;
          top: 6px;
        }
      }
    }
  }
  @media all and (max-width: 1023px) {
    .banner-1 {
      .btn {
        bottom: 6%;

        .icon {
          font-size: 1.6rem;
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .banner-1 {
      height: 100vw;
      width: 100%;

      .btn,
      .h4 {
        bottom: 0;
        position: relative;
      }

      .h4 {
        margin: 0 auto 1.5rem;
        max-width: 55vw;
        padding-top: 62vw;
      }

      .btn {
        font-size: 1.7rem;
        left: 0;
        padding: 1.55rem 0 1.55rem 3rem;
        text-align: center;
        width: 268px;

        .icon {
          font-size: 3.1rem;
          left: 9px;
          top: 9px;
        }
      }
    }
  }
  @media all and (max-width: 320px) {
    .banner-1 {
      .h4 {
        padding-top: 59vw;
      }
    }
  }

  .banner-2 {
    background: url("/files/bitcoin/woman-bg.png") no-repeat center right;
    background-size: contain;
    height: 30.1rem;
    margin: 2rem 8.28rem 0 0;
    position: relative;
    width: 55.1rem;
    @media all and (max-width: 767px) {
      background: url("/files/bitcoin/woman-mobile.jpg") no-repeat center top;
      background-size: 100%;
    }

    h3 {
      bottom: 42%;
      position: absolute;
      right: 0;
    }

    .btn {
      bottom: 12%;
      padding-right: 6rem;
      position: absolute;
      right: -5rem;
      text-align: left;

      .icon {
        font-size: 2.8rem;
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }
  }
  @media all and (max-width: 1700px) {
    .banner-2 {
      height: 24rem;
      margin: 4vh 0 0 0;
      width: 44rem;

      .btn {
        padding-left: 5rem;
        padding-right: 5rem;
        right: -4rem;

        .icon {
          font-size: 2rem;
        }
      }
    }
  }
  @media all and (max-width: 1260px) {
    .banner-2 {
      margin: 4rem 0 0 0;

      .btn {
        padding-left: 4rem;
        padding-right: 4rem;
        right: -3rem;

        .icon {
          font-size: 1.8rem;
          left: 6px;
          top: 6px;
        }
      }
    }
  }
  @media all and (max-width: 1023px) {
    .banner-2 {
      .btn {
        bottom: 7%;
        left: auto;
        right: -10rem;
        text-align: center;

        .icon {
          font-size: 1.6rem;
          position: absolute;
          right: auto;
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    .banner-2 {
      height: 100vw;
      width: 100%;

      .btn,
      .h4 {
        bottom: 0;
        position: relative;
      }

      .h4 {
        margin: 0 auto 1.5rem;
        max-width: 55vw;
        padding-top: 64vw;
      }

      .btn {
        font-size: 1.7rem;
        left: 0;
        padding: 1.55rem 0 1.55rem 3rem;
        text-align: center;
        width: 268px;

        .icon {
          font-size: 3.1rem;
          left: 9px;
          right: auto;
          top: 9px;
        }
      }
    }
  }
  @media all and (max-width: 320px) {
    .banner-2 {
      .h4 {
        padding-top: 62vw;
      }
    }
  }

  .btn-expand-footer {
    background: rgba(#495057, 0.5);
    border-color: #495057;
    bottom: 2rem;
    color: #FFF;
    left: 50%;
    position: fixed;
    transform: translate(-50%, 0);
    z-index: 20;

    &:active,
    &:hover {
      background: rgba(#495057, 1);
    }
    @media all and (max-width: 959px) {
      bottom: 7rem;
      position: absolute;
    }
  }
  @media all and (max-width: 959px) {
    position: relative;

    .scrollindicator {
      display: none;
    }
  }

  .video-js {
    .vjs-big-play-button {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .vjs-play-control {
      &::before {
        line-height: 1.2;
      }
    }

    .vjs-play-progress {
      &::before {
        top: -.4em;
      }
    }
  }
}
