//
// Mixins
// --------------------------------------------------

// Clearfix
//
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// Font size
//
@mixin fontSize($sizeValue: 16, $line: $sizeValue * 1.1) {
  font-size: ($sizeValue / 10) + rem;
}
