
.video-js {
  .vjs-control-bar {
    background: #161f29;
  }
  .vjs-progress-control{
    .vjs-progress-holder {
      background: #343c45;
    }
    .vjs-play-progress {
      &.vjs-slider-bar {
        background: #4083c9;
      }
      &:before {
        color: #4083c9;
        font-size: 1.9rem;
      }
    }
  }
  .vjs-progress-holder {
    height: .5rem;
  }
  &:focus,
  &:hover,
  &:active {
    .vjs-big-play-button {
      background-color: #000;
    }
  }
  .vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
    background: #4083c9;
    &:before {
      color: #4083c9;
    }
  }
  .vjs-paused,
  .vjs-playing {
    border: 2px solid #4083c9;
    padding: 0 2rem;
    border-radius: 20px;
    height: 2.4rem;
    line-height: 2.2rem;
    margin-left: 1rem;
    margin-top: .4rem;

    .vjs-icon-placeholder:before {
      line-height: 2rem;
    }
  }

  .vjs-big-play-button{
    border: none;
    background: none;
    font-size: 5rem;
  }
}
