html {
  font-size: 10px;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'MontserratLight';
  background: $mainBackground;
  color: $mainText;
  min-width: 320px;
  font-size: 1.2rem;
  @media #{$width-1400} {
    font-size: 1.6rem;
  }

  p {
    margin: 0;
    padding: 0;
  }

  &.overflow-hidden {
    overflow: hidden;
  }
}

button {
  border: none;
  outline: none;
  background: none;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.not-allowed {
  cursor: not-allowed !important;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.noselect {
  user-select: none;
}

.hidden-xs {
  @media screen and (max-width: 480px) {
    display: none!important;
  }
}
