.page-about-us {
  .section-bg-1 {
    background-image: url("/files/about-us/welcome.jpg");
    background-position: 0 41px;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 45vw;
  }

  .section-bg-2 {
    background-image: url("/files/about-us/space.jpg");
    background-position: top;
    background-size: 100%;
    min-height: 100vh;
  }

  .section-bg-3 {
    background-image: url("/files/about-us/footer.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 42vw;
  }

  .data-cell {
    color: $white;
    font-family: $font-family-avenir-next-lt-pro-bold;
  }

  .d1 {
    position: relative;

    h2 {
      margin-top: 30px;
    }

    .data-cell {
      font-size: 4.3rem;
      font-weight: normal;
      left: 7rem;
      line-height: 1.05;
      margin: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(0, -50%);
    }

    .data-label {
      border-bottom: 1px solid $green-secondary-text;

      span {
        color: $green-secondary-text;
      }
    }

    .data-lead {
      span {
        display: block;
        font-size: $about-us-header;
        text-align: center;
        text-transform: uppercase;

        &:nth-child(2n-1) {
          font-size: 1.7rem;
        }
      }
    }
  }

  .d2 {
    overflow: hidden;

    .data {
      padding-bottom: 10rem;
    }
  }

  .d2a-cell {
    margin: 0 auto;
    text-align: center;
    width: 75rem;

    h2 {
      color: $green-secondary-text;
      font-size: 4.5rem;
      font-weight: 600;
      margin: 0;
      padding: 5rem 0 2rem;
    }

    p {
      font-family: $font-family-avenir-next-lt-pro;
      font-size: 1.6rem;
      font-weight: 300;
      padding-bottom: 3.5rem;

      &.p-bold {
        font-family: $font-family-avenir-next-lt-pro-bold;
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }

  .img-left-side,
  .img-right-side {
    img {
      max-width: 650px;
    }
  }
  @media all and (max-width: 1279px) {
    .img-left-side,
    .img-right-side {
      img {
        max-width: 550px;
      }
    }
  }

  .d2b-cell,
  .d2c-cell {
    text-align: center;
    width: 41rem;

    h2 {
      font-size: 3rem;
    }

    p {
      font-family: $font-family-avenir-next-lt-pro;
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }

    .bold-color,
    strong {
      font-family: $font-family-avenir-next-lt-pro-bold;
    }
  }
  @media all and (max-width: 1279px) {
    .d2b-cell,
    .d2c-cell {
      width: 36rem;
    }
  }

  .col-2-wrapper {
    margin: 0 auto;
    max-width: 110rem;
  }

  .d2b-cell {
    float: left;

    .bold-color,
    h2 {
      color: $myAccountActiveBlue;
    }
  }
  @media all and (max-width: 1279px) {
    .d2b-cell {
      margin-left: 9rem;
    }
  }

  .img-right-side {
    float: right;
  }

  .d2c-cell {
    float: right;

    .bold-color,
    h2 {
      color: $green-secondary-text;
    }
  }
  @media all and (max-width: 1279px) {
    .d2c-cell {
      margin-right: 6rem;
    }
  }

  .img-left-side {
    float: left;
  }

  .d3 {
    overflow: hidden;
    padding-top: 4vw;
    position: relative;

    h2 {
      font-size: 2rem;
    }

    p {
      font-family: $font-family-avenir-next-lt-pro;
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }

    .d3-left-side,
    .d3-right-side {
      position: absolute;
      top: 15rem;
      width: 30rem;

      h2 {
        position: relative;
        text-transform: uppercase;

        &::before {
          content: "";
          position: absolute;
        }
      }
    }

    .d3-left-side {
      left: 18rem;

      h2 {
        color: $myAccountActiveBlue;

        &::before {
          background: $myAccountActiveBlue;
          height: 2px;
          left: -100%;
          top: -5px;
          width: calc(100% + 182px);
        }
      }
    }

    .d3-right-side {
      right: 15rem;
      text-align: right;

      h2 {
        color: $green-secondary-text;

        &::before {
          background: $green-secondary-text;
          bottom: -5px;
          height: 2px;
          right: -100%;
          width: calc(100% + 152px);
        }
      }
    }

    .d3-center {
      display: inline-block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);

      img {
        max-width: 27vw;
      }
    }
  }
}
